import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import TestErrors from "../../features/errors/TestError";
import App from "../layout/App";
import RequireAuth from "./RequireAuth";
import Catalog from "../../features/catalog/Catalog";
import MenuItemDetails from "../../features/catalog/MenuItemDetails";
import Inventory from "../../features/admin/Inventory";
import Orders from "../../features/orders/Orders";
import CheckoutWrapper from "../../features/checkout/CheckoutWrapper";
import BasketPage from "../../features/basket/BasketPage";
import Register from "./../../features/account/Register";
import Login from "../../features/account/Login";
import AccountPage from "../../features/account/AccountPage";
import OrderDetailed from "../../features/orders/OrderDetailed";
import Category from "../../features/admin/Category";
import AdminPage from "../../features/admin/AdminPage";
import MenuItemForm from "../../features/admin/MenuItemForm";
import RegisterSuccessPage from "../../features/account/RegisterSucessPage";
import PaymentResultPage from "../../features/checkout/custom/PaymentResultPage";
import ConfirmEmail from "../../features/account/ConfirmEmail";
import ResetPassword from "../../features/account/ResetPassword";
import RedirectHandler from "../../features/account/RedirectHandler";

const basename = process.env.PUBLIC_URL;

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "confirm-email", element: <ConfirmEmail /> },
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "register-success", element: <RegisterSuccessPage /> },
      { path: "signin-facebook", element: <RedirectHandler /> },
      { path: "signin-google", element: <RedirectHandler /> },
      { path: "Account/ResetPassword", element: <ResetPassword /> },
      { path: "catalog", element: <Catalog /> },
      { path: "catalog/:id", element: <MenuItemDetails /> },
      { path: "not-found", element: <NotFound /> },
      { path: "basket", element: <BasketPage /> },
      { path: "checkout-result", element: <PaymentResultPage success /> },

      {
        element: <RequireAuth />,
        children: [
          { path: "account", element: <AccountPage /> },
          { path: "errors", element: <TestErrors /> },
        ],
      },

      { path: "not-found", element: <NotFound /> },
      { path: "server-error", element: <ServerError /> },
      { path: "*", element: <Navigate replace to='/not-found' /> },
      // authenticated routes
      {
        element: <RequireAuth />,
        children: [
          { path: "checkout", element: <CheckoutWrapper /> },
          { path: "orders", element: <Orders /> },
          { path: "orders/:id", element: <OrderDetailed /> },
        ],
      },
      // admin routes
      {
        element: <RequireAuth roles={["Admin"]} />,
        children: [
          { path: "inventory", element: <Inventory /> },
          {
            path: "inventory/:id",
            element: <MenuItemForm />,
          },
          {
            path: "inventory/new",
            element: <MenuItemForm />,
          },
          { path: "category", element: <Category /> },
          { path: "admin", element: <AdminPage /> },
          // { path: 'user/:id', element: <UserDetail /> },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routes, { basename });
