import { useState } from "react";
import { Grid, Button, Box } from "@mui/material";
import { MenuItem } from "../../../app/models/menuItem";
import MenuItemCard from "./MenuItemCard";
import MenuItemCardSkeleton from "../MenuItemCardSkeleton";
import { useStore } from "../../../app/stores/store";
import { useTheme as useCustomTheme } from "../../../app/contexts/ThemeContext";

interface Props {
  menuItems: MenuItem[];
}

export default function MenuItemList({ menuItems }: Props) {
  const { catalogStore: menuItemStore } = useStore();
  const { loadingInitial } = menuItemStore;
  const [visibleItems, setVisibleItems] = useState(8);
  const { theme: customTheme } = useCustomTheme();

  const MenuItemCardSkeletonList = () => {
    return (
      <Grid container spacing={2}>
        {Array.from({ length: 8 }).map((_, index) => (
          <Grid key={index} item xs={12} sm={6} md={6}>
            <MenuItemCardSkeleton />
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderMenuItems = () => {
    const visibleMenuItems = menuItems.slice(0, visibleItems);
    const remainingItems = 8 - visibleMenuItems.length;
    const dummyItems = Array.from({ length: remainingItems }, (_, index) => ({
      id: `dummy-${index}`,
      name: "",
      description: "",
      pictureUrl: "",
      price: 0,
      tag: "",
      type: "",
      category: "", // Add default value for category
      quantityInStock: 0, // Add default value for quantityInStock
      options: [], // Add default value for options
      displayOrder: Number.MAX_SAFE_INTEGER,
    }));

    const allItems = [...visibleMenuItems, ...dummyItems];

    const sortedItems = allItems.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );

    return allItems.map((menuItem) => (
      <Grid key={menuItem.id} item xs={12} sm={12} md={6}>
        <MenuItemCard
          menuItem={menuItem as MenuItem}
          style={{
            visibility:
              typeof menuItem.id === "string" && menuItem.id.startsWith("dummy")
                ? "hidden"
                : "visible",
          }}
        />
      </Grid>
    ));
  };

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4);
  };

  return (
    <>
      {loadingInitial ? (
        <MenuItemCardSkeletonList />
      ) : (
        <>
          <Grid container spacing={1}>
            {renderMenuItems()}
          </Grid>
          {visibleItems < menuItems.length && (
            <Box textAlign='center' mt={2}>
              <Button
                variant='contained'
                onClick={handleLoadMore}
                sx={{
                  bgcolor: customTheme.primaryColor,
                  "&:hover": { bgcolor: customTheme.primaryHoverColor },
                  borderRadius: "20px",
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                Load More
              </Button>
            </Box>
          )}
          {visibleItems >= menuItems.length && visibleItems > 8 && (
            <Box textAlign='center' mt={2}>
              <Button
                variant='contained'
                onClick={() => setVisibleItems(8)}
                sx={{
                  bgcolor: customTheme.primaryColor,
                  "&:hover": { bgcolor: customTheme.primaryHoverColor },
                  borderRadius: "20px",
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                Collapse
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
}
