import { Grid } from "@mui/material";
import { MenuItem } from "../../app/models/menuItem";
import MenuItemCard from "./MenuItemCard";
import MenuItemCardSkeleton from "./MenuItemCardSkeleton";
import { useStore } from "../../app/stores/store";
import InfiniteScroll from "react-infinite-scroller";
import { useState, useEffect } from "react";
import { PagingParams } from "../../app/models/pagination";

interface Props {
  menuItems: MenuItem[];
}

export default function MenuItemList({ menuItems }: Props) {
  const { catalogStore: menuItemStore } = useStore();
  const { loadingInitial, fetchMenuItems, pagination, setPagingParams } =
    menuItemStore;
  const [loadingNext, setLoadingNext] = useState(false);

  function handleGetNext() {
    setLoadingNext(true);
    setPagingParams(new PagingParams(pagination!.currentPage + 1));
    fetchMenuItems().then(() => setLoadingNext(false));
  }

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;
      // menuItemStore.loadMoreMenuItems();
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const MenuItemCardSkeletonList = () => {
    return (
      <Grid container>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid key={index} item xs={12}>
            <MenuItemCardSkeleton />
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderGroupedMenuItems = () => {
    return Object.keys(groupedMenuItems).map((categoryKey) => (
      <div id={`category-${categoryKey}`} key={categoryKey}>
        {groupedMenuItems[categoryKey].map((menuItem) => (
          <Grid key={menuItem.id} item xs={12} width={"100%"}>
            <MenuItemCard menuItem={menuItem} />
          </Grid>
        ))}
      </div>
    ));
  };

  function groupMenuItemsByCategory(menuItems: MenuItem[]) {
    const groupedMenuItems: { [key: string]: MenuItem[] } = {};

    menuItems.forEach((menuItem, index) => {
      const categoryKey = menuItem.category;

      if (!groupedMenuItems[categoryKey]) {
        groupedMenuItems[categoryKey] = [];
      }

      groupedMenuItems[categoryKey].push(menuItem);
    });

    return groupedMenuItems;
  }

  const groupedMenuItems = groupMenuItemsByCategory(menuItems);

  function scrollToCategory(categoryId: string) {
    const element = document.getElementById(`category-${categoryId}`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  // if (menuItems.length === 0) return <LoadingComponent content='Loading...' />;
  return (
    <>
      {loadingInitial && !loadingNext ? (
        <MenuItemCardSkeletonList />
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={handleGetNext}
          hasMore={
            !loadingNext &&
            !!pagination &&
            pagination.currentPage < pagination.totalPages
          }
          // loader={<div key='loader'></div>}
          // useWindow={true}
          // element='div'
          style={{ marginBottom: "7rem" }}
          initialLoad={false}
        >
          {renderGroupedMenuItems()}
        </InfiniteScroll>
      )}
    </>
  );
}
