import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Typography, Grid, useTheme } from "@mui/material";
import { Image } from "@mui/icons-material";
import { useTheme as useCustomTheme } from "../../../app/contexts/ThemeContext";

interface Props {
  success: boolean;
}

const PaymentResultPage = ({ success }: Props) => {
  const location = useLocation();
  const theme = useTheme();

  const isSuccess = location.state?.success;
  const { theme: customTheme } = useCustomTheme();

  return (
    <Grid container justifyContent='center' alignItems='center' height='50vh'>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/checkout/result/tania-melnyczuk-xeTv9N2FjXA-unsplash 1.png`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "20%",
            height: "auto",
          }}
        />
        <img
          src={`${process.env.PUBLIC_URL}/assets/checkout/result/mockup-graphics-lDhhUl3Gp3Q-unsplash 1.png`}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "20%",
            height: "auto",
          }}
        />

        <Box sx={{ position: "relative" }}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/common/Ellipse 17.png`}
            alt='Center'
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "30%",
              height: "auto",
              zIndex: -1,
            }}
          />
          <Typography
            variant='h5'
            gutterBottom
            sx={{
              fontWeight: 500,
            }}
          >
            {isSuccess
              ? "You have successfully Ordered!"
              : "Uh oh, something went wrong when ordering"}
          </Typography>
          <Typography variant='body1' gutterBottom>
            {isSuccess
              ? "We will send you a confirmation through Email :)"
              : "Sorry, please try again later :("}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "2rem" }}>
          {isSuccess ? (
            <Button
              variant='contained'
              component={Link}
              to={isSuccess ? "/" : "/checkout"}
              sx={{
                bgcolor: customTheme.primaryColor,
                "&:hover": { bgcolor: customTheme.primaryHoverColor },
                borderRadius: "20px",
                textTransform: "capitalize",
                boxShadow: "none",
                color: "#fff !important",
                paddingInline: "2rem",
              }}
            >
              Go back to Menu
            </Button>
          ) : (
            <Button
              variant='contained'
              component={Link}
              to='/checkout'
              sx={{
                bgcolor: customTheme.primaryColor,
                "&:hover": { bgcolor: customTheme.primaryHoverColor },
                borderRadius: "20px",
                textTransform: "capitalize",
                boxShadow: "none",
                color: "#fff !important",
                paddingInline: "2rem",
              }}
            >
              Go back to Checkout
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default PaymentResultPage;
