import { useEffect } from "react";
import { useStore } from "../stores/store";

export default function useMenuItems() {
  const { catalogStore } = useStore();
  const {
    menuItems,
    menuItemsLoaded,
    filtersLoaded,
    categoriesLoaded,
    tags,
    types,
    categories,
    metaData,
    fetchCategories,
    fetchMenuItems,
    fetchFilters,
  } = catalogStore;

  useEffect(() => {
    if (!categories) fetchCategories();
  }, [categoriesLoaded, fetchCategories]);

  useEffect(() => {
    if (!menuItemsLoaded) fetchMenuItems();
  }, [menuItemsLoaded, fetchMenuItems]);

  useEffect(() => {
    if (!filtersLoaded) fetchFilters();
  }, [filtersLoaded, fetchFilters]);

  return {
    menuItems,
    menuItemsLoaded,
    filtersLoaded,
    tags,
    types,
    metaData,
    categories,
  };
}
