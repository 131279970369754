import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import { toast } from "react-toastify";

interface Props {
  roles?: string[];
}

function isTokenExpired(token: any) {
  if (!token) return true;
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const exp = decodedToken.exp * 1000;
  return Date.now() >= exp;
}

export default function RequireAuth({ roles }: Props) {
  const {
    userStore: { user, signOut },
  } = useStore();
  const location = useLocation();

  const token = user?.token;

  if (!user || isTokenExpired(token)) {
    toast.error("The session has expired. Please sign in again.");
    signOut();
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (
    roles &&
    user.roles &&
    !roles.some((role) => user?.roles?.includes(role))
  ) {
    toast.error("Not authorized to access this page.");
    return <Navigate to='/' replace />;
  }

  return <Outlet />;
}
