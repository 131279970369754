import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import {
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  Checkbox,
  Grid,
  CardMedia,
  Divider,
  Box,
  IconButton,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import { currencyFormat } from "../../../app/util/util";
import {
  Add,
  ArrowBackIos,
  ArrowForwardIos,
  Remove,
} from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { routes } from "../../../app/router/Routes";
import { useTheme as useCustomTheme } from "../../../app/contexts/ThemeContext";

export default observer(function MenuItemDetails() {
  const { modalStore, catalogStore, basketStore } = useStore();
  const { getSelectedOptions, menuItem, fetchMenuItem, menuItems } =
    catalogStore;
  const { basket, addItem } = basketStore;
  const { theme: customTheme } = useCustomTheme();

  const { id } = useParams<{ id: string }>();

  if (menuItem === null) fetchMenuItem(parseInt(id!));

  const { setMenuItem } = catalogStore;
  const options = menuItem?.options || [];
  const [quantity, setQuantity] = useState(1);
  const item =
    basket && basket.items
      ? basket.items.find((i) => i.menuItemId === menuItem?.id)
      : undefined;

  const selectedOptionPrice = options.reduce((total, option) => {
    const selectedChoices = option.choices.filter((choice) => choice.selected);
    const choicePrices = selectedChoices.map((choice) => choice.price);
    const optionPrice = choicePrices.reduce((a, b) => a + b, 0);
    return total + optionPrice;
  }, 0);

  function handleInputChange(e: any) {
    if (e.target.value >= 0) {
      let newQuantity = parseInt(e.target.value);
      if (newQuantity <= 0) {
        newQuantity = 1;
      }

      setQuantity(newQuantity);
    }
  }

  function handleUpdateCart() {
    if (quantity > 0) {
      const updatedQuantity = item ? quantity - item.quantity : quantity;
      const selectedOptions = getSelectedOptions(menuItem!.options);
      addItem(item?.id!, menuItem?.id!, quantity, selectedOptions);

      catalogStore.resetSelectedOptions(menuItem!.options);
      modalStore.closeModal();
    }
  }

  function setQuantityHandler(value: number) {
    if (value >= 0) {
      let newQuantity = value;
      if (newQuantity < 0) {
        newQuantity = 0;
      }
      setQuantity(newQuantity);
    }
  }

  function handleChoiceChange(optionId: number, choiceId: number) {
    catalogStore.updateMenuItemOption(optionId, choiceId);
  }

  const mostLovedMenuItems = Array.from(menuItems.values())
    .filter((menuItem) => menuItem.quantityInStock > 0)
    .sort((a, b) => a.quantityInStock - b.quantityInStock)
    .slice(0, 10);

  const [carouselIndex, setCarouselIndex] = useState(0);

  const handlePrevSlide = () => {
    if (carouselIndex < mostLovedMenuItems.length) {
      setCarouselIndex(carouselIndex + 1);
    } else {
      setCarouselIndex(0);
    }
  };

  const handleNextSlide = () => {
    if (carouselIndex > 1) {
      setCarouselIndex(carouselIndex - 1);
    } else {
      setCarouselIndex(mostLovedMenuItems.length);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {menuItem && (
        <>
          <Grid container xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign={"center"}>
                <Typography
                  variant='h4'
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "1rem", sm: "1.5rem", md: "2rem" },
                  }}
                >
                  {menuItem.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                  }}
                >
                  {menuItem.description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CardMedia
                  component='img'
                  sx={{
                    maxWidth: { xs: "auto", sm: "auto", md: "auto" },
                    maxHeight: { xs: "15rem", sm: "20rem", md: "25rem" },
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  image={
                    menuItem.pictureUrl
                      ? menuItem.pictureUrl
                      : `${process.env.PUBLIC_URL}/assets/placeholder.png`
                  }
                  alt={menuItem.name}
                />
              </Grid>
              {options.map((option) => (
                <Grid item xs={12} key={option.id}>
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                    }}
                  >
                    {option.name}&nbsp;&nbsp;&nbsp;
                    {option.choices.length > 0 && (
                      <span style={{ color: "gray", fontSize: "1rem" }}>
                        {option.required
                          ? `Select 1 of ${option.choices.length}`
                          : `Select up to ${option.choices.length} (Optional)`}
                      </span>
                    )}
                  </Typography>

                  <Grid container>
                    {option.choices.map((choice) => (
                      <Grid container item xs={12} key={choice.id}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "0.75rem",
                                sm: "1rem",
                                md: "1.25rem",
                              },
                            }}
                          >
                            {choice.name}&nbsp;&nbsp;&nbsp;
                            <span style={{ color: "gray" }}>
                              + {currencyFormat(choice.price)}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {option.required ? (
                            <FormControlLabel
                              value={choice.id}
                              label={""}
                              control={<Radio sx={{ color: "primary.main" }} />}
                              checked={choice.selected}
                              onChange={() =>
                                handleChoiceChange(option.id, choice.id)
                              }
                              sx={{ marginRight: "-1rem" }}
                            />
                          ) : (
                            <FormControlLabel
                              value={choice.id}
                              label={""}
                              control={
                                <Checkbox sx={{ color: "primary.main" }} />
                              }
                              checked={choice.selected}
                              onChange={() =>
                                handleChoiceChange(option.id, choice.id)
                              }
                              sx={{ marginRight: "-1rem" }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Divider
                            sx={{
                              borderTop: "0.5px solid #e3e3e3",
                              marginTop: "-0.5rem",
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {!isMobile ? (
            <Grid item xs={12}>
              <Divider
                sx={{
                  borderTop: "0.5px solid #e3e3e3",
                  my: "2rem",
                  marginInline: "-3rem",
                  width: { xs: "20rem", sm: "29rem", md: "39rem" },
                }}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} container my={4}>
            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
              <Typography
                variant='h6'
                component='div'
                sx={{
                  fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                }}
              >
                Items people loved
              </Typography>
            </Grid>

            {!isMobile ? (
              <Grid
                item
                xs={6}
                sx={{ textAlign: { xs: "center", sm: "right" } }}
              >
                <IconButton
                  onClick={() => {}}
                  sx={{
                    color: "#000",
                    borderRadius: "100%",
                    width: { xs: "20px !important", sm: "30px !important" },
                    height: { xs: "20px !important", sm: "30px !important" },
                    backgroundColor: "#dedede",
                    marginInline: { sx: "1rem", sm: "1.5rem" },
                  }}
                >
                  <ArrowBackIos
                    sx={{
                      color: "#000",
                      fontSize: { xs: "0.75rem", sm: "1rem", md: "1.25rem" },
                      marginLeft: { sx: "-20px !important" },
                    }}
                    onClick={handlePrevSlide}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {}}
                  sx={{
                    color: "#000",
                    borderRadius: "100%",
                    width: { xs: "20px !important", sm: "30px !important" },
                    height: { xs: "20px !important", sm: "30px !important" },
                    backgroundColor: "#dedede",
                    marginInline: { sx: "1rem", sm: "1.5rem" },
                  }}
                >
                  <ArrowForwardIos
                    sx={{
                      color: "#000",
                      fontSize: { xs: "0.75rem", sm: "1rem", md: "1.25rem" },
                    }}
                    onClick={handleNextSlide}
                  />
                </IconButton>
              </Grid>
            ) : null}

            <Grid item xs={12} sx={{}}>
              <Carousel
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                infiniteLoop
                autoPlay
                interval={5000}
                stopOnHover={false}
                swipeable
                emulateTouch
                selectedItem={carouselIndex}
              >
                {mostLovedMenuItems.map((menuItem) => (
                  <Grid item key={menuItem.id} xs={12}>
                    <Card
                      sx={{
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                        position: "relative",
                        margin: "1rem",
                        marginInline: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMenuItem(menuItem);
                        modalStore.openModal(<MenuItemDetails />);
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "1rem !important",
                        }}
                      >
                        <Typography
                          variant='body1'
                          align='left'
                          sx={{
                            fontSize: {
                              xs: "0.75rem",
                              sm: "1rem",
                              md: "1.25rem",
                            },
                          }}
                        >
                          {menuItem.name}
                        </Typography>
                        <Typography
                          variant='body1'
                          align='right'
                          sx={{
                            fontSize: {
                              xs: "0.75rem",
                              sm: "1rem",
                              md: "1.25rem",
                            },
                          }}
                        >
                          {currencyFormat(menuItem.price)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Carousel>
            </Grid>
          </Grid>

          {!isMobile ? (
            <Grid item xs={12}>
              <Divider
                sx={{
                  borderTop: "0.5px solid #e3e3e3",
                  my: "2rem",
                  marginInline: "-3rem",
                  width: { xs: "20rem", sm: "29rem", md: "39rem" },
                }}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => setQuantityHandler(quantity - 1)}
                  sx={{
                    color: "#000",
                    borderRadius: "100%",
                    width: "20px !important",
                    height: "20px !important",
                    backgroundColor: "#dedede",
                    marginInline: { sx: "0.5rem", sm: "1rem" },
                  }}
                >
                  <Remove
                    sx={{
                      color: "#000",
                      fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                    }}
                  />
                </IconButton>
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{
                    mx: 2,
                    display: "inline-block",
                    fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                    marginInline: { sx: "1rem", sm: "1.5rem" },
                  }}
                >
                  {quantity}
                </Typography>
                <IconButton
                  onClick={() => setQuantityHandler(quantity + 1)}
                  sx={{
                    color: "#000",
                    borderRadius: "100%",
                    width: "20px !important",
                    height: "20px !important",
                    backgroundColor: "#dedede",
                    marginInline: { sx: "0.5rem", sm: "1rem" },
                  }}
                >
                  <Add
                    sx={{
                      color: "#000",
                      fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  loading={basketStore.status !== "idle"}
                  onClick={() => handleUpdateCart()}
                  sx={{
                    bgcolor: customTheme.primaryColor,
                    "&:hover": { bgcolor: customTheme.primaryHoverColor },
                    borderRadius: "20px",
                    textTransform: "capitalize",
                    boxShadow: "none",
                    color: "#fff !important",
                    paddingInline: { sx: "1.25rem", sm: "2rem" },
                    fontSize: { xs: "0.75rem", sm: "1rem", md: "1.25rem" },
                  }}
                  variant='contained'
                >
                  {quantity > 0 ? (
                    <>
                      Add To Cart{" "}
                      <>
                        (
                        {currencyFormat(
                          menuItem.price * quantity +
                            selectedOptionPrice * quantity
                        )}
                        )
                      </>
                    </>
                  ) : (
                    "0 item quantity"
                  )}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});
