import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { PaginatedResult } from "../models/pagination";
import { router } from "../router/Routes";
import { store } from "../stores/store";
import { Order } from "../models/order";
import { OptionChoiceDto } from "../models/menuItem";
import { Category as CategoryModel } from "../models/category";
import {
  DeleteAccountFormValues,
} from "../models/user";

const sleep = () => new Promise((resolve) => setTimeout(resolve, 500));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.userStore.user?.token || localStorage.getItem("jwt");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep();
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response;
    }
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status } = error.response as AxiosResponse;
    switch (status) {
      case 400:
        if (data.errors) {
          const modelStateErrors: string[] = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateErrors.push(data.errors[key]);
            }
          }
          throw modelStateErrors.flat();
        }
        toast.error(data.title);
        break;
      case 401:
        toast.error(data.title);
        break;
      case 403:
        toast.error("You are not allowed to do that!");
        break;
      case 500:
        router.navigate("/server-error", { state: { error: data } });
        break;
      default:
        break;
    }

    return Promise.reject(error.response);
  }
);

const requests = {
  get: (url: string, params?: any) =>
    axios.get(url, { params }).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, data: FormData) =>
    axios
      .post(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
  putForm: (url: string, data: FormData) =>
    axios
      .put(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
  getWithParams: (url: string, params?: URLSearchParams) =>
    axios.get(url, { params }).then(responseBody),
};


const OAuth = {
  googleLogin: () => requests.get("auth/google-signin"),
  facebookLogin: () => requests.get("auth/fb-signin"),
};

const Account = {
  login: (values: any) => requests.post("account/login", values),
  kakaoLogout: () => requests.get("account/kakao/logout"),
  loginJwt: () => requests.get("account/login"),
  register: (values: any) => requests.post("account/register", values),
  current: () => requests.get("account/currentUser"),
  fetchAddress: () => requests.get("account/savedAddress"),
  updateProfile: (profile: any) =>
    requests.put(`/account/profileUpdate`, profile),
  updatePassword: (profile: any) =>
    requests.put(`/account/passwordUpdate`, profile),
  kakaoLogin: () => requests.get("account/kakao/Login"),
  deleteUserAccount: (value: DeleteAccountFormValues) =>
    requests.post("/account/delete", value),
  forgotPassword: (email: string) =>
    requests.post(`/account/forgotpassword`, { email }),
  resetPassword: (
    email: string,
    token: string,
    newPassword: string,
    confirmNewPassword: string
  ) =>
    requests.post(`/account/resetpassword`, {
      email,
      token,
      newPassword,
      confirmNewPassword,
    }),
  logout: () => requests.post("/account/logout", {}),
};

const User = {
  list: () => requests.get("/account/users"),
  details: (id: String) => requests.get(`/account/users/${id}`),
  update: (user: any) => requests.put(`/account/users/${user.id}`, user),
  delete: (id: String) => requests.delete(`/account/users/${id}`),
  create: (user: any) => requests.post(`/account/users`, user),
};

const Category = {
  list: () => requests.get("/Categories"),
  details: (id: Number) => requests.get(`/Categories/${id}`),
  create: (category: CategoryModel) => requests.post("/Categories", category),
  update: (category: CategoryModel) =>
    requests.put(`/Categories/${category.id}`, category),
  delete: (id: Number) => requests.delete(`/Categories/${id}`),
};

const Catalog = {
  list: (params: URLSearchParams) =>
    requests.getWithParams("/MenuItems", params),
  details: (id: number) => requests.get(`/MenuItems/${id}`),
  fetchFilters: () => requests.get("/MenuItems"),
  categories: () => requests.get("/MenuItems/categories"),
};

const Basket = {
  get: () => requests.get("/basket"),
  addItem: (
    menuItemId: number,
    quantity = 1,
    optionChoices?: OptionChoiceDto[]
  ) => {
    const params = new URLSearchParams();
    params.append("basketItemId", menuItemId.toString());
    params.append("menuItemId", menuItemId.toString());
    params.append("quantity", quantity.toString());

    const jsonString = optionChoices ? JSON.stringify(optionChoices) : "[]";
    return axios
      .post("basket", jsonString, {
        headers: { "Content-Type": "application/json" },
        params: params,
      })
      .then(responseBody);
  },
  updateItem: (
    basketItemId: number,
    menuItemId: number,
    quantity: number,
    optionChoices?: OptionChoiceDto[]
  ) => {
    const params = new URLSearchParams();
    params.append("basketItemId", basketItemId.toString());
    params.append("menuItemId", menuItemId.toString());
    params.append("quantity", quantity.toString());

    const jsonString = optionChoices ? JSON.stringify(optionChoices) : "[]";
    return axios
      .put("basket", jsonString, {
        headers: { "Content-Type": "application/json" },
        params: params,
      })
      .then(responseBody);
  },
  removeItem: (basketItemId: number, menuItemId: number, quantity = 1) =>
    requests.delete(
      `basket?basketItemId=${basketItemId}&menuItemId=${menuItemId}&quantity=${quantity}`
    ),
};

const Orders = {
  list: (): Promise<Order[]> => requests.get("orders"),
  fetch: (id: number) => requests.get(`orders/${id}`),
  create: (values: any) => requests.post("/orders/createOrder", values),
  update: (id: number, values: any) => requests.put(`orders/${id}`, values),
};

const Payments = {
  createPaymentIntent(): Promise<any> {
    return requests.post("payments", {});
  },
  retrieveIntent(paymentIntentId: String): Promise<any> {
    return requests.get(`payments/${paymentIntentId}`);
  },
  updatePaymentIntent(newAmount: number): Promise<any> {
    return requests.put(`payments`, { newAmount });
  },
};

const Admin = {
  orderList: (): Promise<Order[]> => requests.get("orders/admin"),
  createMenuItem: (menuItem: any) =>
    requests.postForm("menuItems", createFormData(menuItem)),
  updateMenuItem: (menuItem: any) =>
    requests.putForm("menuItems", createFormData(menuItem)),
  // requests.put('menuItems', menuItem),
  deleteMenuItem: (id: number) => requests.delete(`menuItems/${id}`),
};

function createFormData(item: any) {
  let formData = new FormData();
  for (const key in item) {
    if (item.hasOwnProperty(key)) {
      if (key === "options" && typeof item[key] === "object") {
        formData.append(key, JSON.stringify(item[key]));
      } else {
        formData.append(key, item[key]);
      }
    }
  }
  return formData;
}

const agent = {
  Account,
  Catalog,
  Basket,
  Orders,
  Payments,
  Admin,
  Category,
  User,
  OAuth,
};

export default agent;
