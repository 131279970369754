import { LoadingButton } from "@mui/lab";
import {
  Grid,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import ContentContainer from "../../app/common/container/ContentContainer";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

interface UserFormValues {
  username: string;
  email: string;
  password: string;
  displayName?: string;
}

export default function Register() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors, isValid },
    watch,
  } = useForm({
    mode: "onTouched",
  });
  const password = watch("password");
  const { theme: customTheme } = useCustomTheme();

  function handleApiErrors(errors: any) {
    console.log(errors[0]);
    toast.error(errors[0]);
    if (errors) {
      errors.forEach((error: string, index: number) => {
        if (error.includes("Password")) {
          setError("password", { message: error });
        } else if (error.includes("Email")) {
          setError("email", { message: error });
        } else if (error.includes("Username")) {
          setError("username", { message: error });
        }
      });
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContentContainer>
      <Grid
        container
        alignItems='center'
        sx={{ sm: { m: 5 }, md: { m: 5 } }}
        px={{ xs: 2, sm: 20, md: 30 }}
      >
        <Grid item xs={12}>
          <Typography
            variant='h6'
            align='center'
            sx={{
              mt: { xs: "4rem" },
              fontWeight: 500,
            }}
          >
            Sign up with Email
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          component='form'
          onSubmit={handleSubmit((data) => {
            const userFormValues: UserFormValues = {
              username: data.email,
              displayName: data.email,
              email: data.email,
              password: data.password,
            };

            agent.Account.register(userFormValues)
              .then(() => {
                toast.success("Registration successful - you can now login");
                navigate("/register-success");
              })
              .catch((error) => handleApiErrors(error));
          })}
          noValidate
          sx={{ mt: 5 }}
        >
          {/* <InputLabel>User name</InputLabel>
          <TextField
            required
            fullWidth
            {...register("username", {
              required: "Username is required",
              minLength: {
                value: 1,
                message: "Username should be at least 1 character",
              },
              maxLength: {
                value: 12,
                message: "Username should not exceed 12 characters",
              },
            })}
            error={!!errors.username}
            helperText={errors?.username?.message as string}
            sx={{
              mb: 1,
              "& .MuiInputBase-input": {
                borderRadius: "30px",
                backgroundColor: "#e3e3e3",
                height: "1px",
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                "& fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3",
                },
              },
            }}
          /> */}
          <InputLabel>Email</InputLabel>
          <TextField
            required
            fullWidth
            {...register("email", {
              required: "Email is required",
              pattern: {
                value:
                  /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                message: "Not a valid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors?.email?.message as string}
            sx={{
              mb: 1,
              "& .MuiInputBase-input": {
                borderRadius: "30px",
                backgroundColor: "#e3e3e3",
                height: "1px",
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                "& fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3",
                },
              },
            }}
          />
          <InputLabel>Password</InputLabel>
          <TextField
            required
            fullWidth
            type='password'
            {...register("password", {
              required: "password is required",
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                message: "Password does not meet complexity requirements",
              },
            })}
            error={!!errors.password}
            helperText={errors?.password?.message as string}
            sx={{
              mb: 1,
              "& .MuiInputBase-input": {
                borderRadius: "30px",
                backgroundColor: "#e3e3e3",
                height: "1px",
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                "& fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3",
                },
              },
            }}
          />
          <InputLabel>Confirm Password</InputLabel>
          <TextField
            required
            fullWidth
            type='password'
            {...register("confirm_password", {
              required: "Confirm password is required",
              validate: (value) =>
                value === password || "The passwords do not match",
            })}
            error={!!errors.confirm_password}
            helperText={errors?.confirm_password?.message as string}
            sx={{
              mb: 1,
              "& .MuiInputBase-input": {
                borderRadius: "30px",
                backgroundColor: "#e3e3e3",
                height: "1px",
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                "& fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3",
                },
              },
            }}
          />
          <Grid xs={12} mt={4}>
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              loading={isSubmitting}
              loadingPosition={isMobile ? "center" : "start"}
              disabled={!isValid || isSubmitting}
              sx={{
                display: "flex",
                justifyContent: "center",
                my: 2,
                margin: "auto",
                bgcolor: customTheme.primaryColor,
                "&:hover": { bgcolor: customTheme.primaryHoverColor },
                borderRadius: "30px",
                textTransform: "capitalize",
                boxShadow: "none",
                width: "70%",
              }}
            >
              Sign Up
            </LoadingButton>
            {/* <Grid
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link to="/login">{"Have an account? Log In"}</Link>
          </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}
