import { observer } from "mobx-react-lite";
import {
  Container,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  CssBaseline,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default observer(function ContentContainer({ children }: any) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {/* <CssBaseline /> */}
      <Container
        maxWidth={isMobile ? "sm" : "md"}
        disableGutters
        sx={{
          px: { xs: 1, sm: 0, md: 0 },
          pt: { xs: 6, sm: 2, md: 2 },
          marginBottom: { xs: 5 },
        }}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.default",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          {/* <IconButton
          aria-label="back"
          onClick={goBack}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
          }}
        >
          <ArrowBackIcon />
        </IconButton> */}
          {children}
        </Box>
      </Container>
    </>
  );
});
