import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Paper,
  IconButton,
  Box,
  Container,
} from "@mui/material";
import { useStore } from "../../app/stores/store";
import { Category } from "./../../app/models/category";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export default observer(function CategoryList() {
  const { categoryStore } = useStore();
  const {
    createCategory,
    loadCategories,
    categories,
    deleteCategory,
    updateCategory,
  } = categoryStore;
  const [category, setCategory] = useState<Category>({
    id: 0,
    name: "",
    description: "",
    parentCategoryId: undefined,
  });

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  function handleSubmit() {
    createCategory(category);
    setCategory({
      id: 0,
      name: "",
      description: "",
      parentCategoryId: undefined,
    });
  }

  function handleInputChange(event: any) {
    const { name, value } = event.target;
    setCategory((prevState) => ({
      ...prevState,
      [name]: value === "" ? null : value,
    }));
  }
  return (
    <Box sx={{ my: 4, flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            name='name'
            label='Category Name'
            value={category.name}
            onChange={handleInputChange}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name='description'
            label='Description'
            value={category.description}
            onChange={handleInputChange}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            labelId='parent-category-select-label'
            name='parentCategoryId'
            value={category.parentCategoryId || ""} // Display empty string if null
            onChange={handleInputChange}
            variant='outlined'
            displayEmpty // Allows the display of a non-selectable empty first item
          >
            <MenuItem value=''>
              <em>Select Parent Category</em>
            </MenuItem>
            {categories.map((parentCategory) => (
              <MenuItem key={parentCategory.id} value={parentCategory.id}>
                {parentCategory.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={handleSubmit}
            size='large'
            fullWidth
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Parent Category</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category) => (
                  <TableRow key={category.id}>
                    <TableCell>
                      <TextField
                        name='name'
                        defaultValue={category.name}
                        onChange={(e) =>
                          updateCategory({ ...category, name: e.target.value })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name='description'
                        defaultValue={category.description}
                        onChange={(e) =>
                          updateCategory({
                            ...category,
                            description: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        name='parentCategoryId'
                        defaultValue={category.parentCategoryId}
                        onChange={(e) =>
                          updateCategory({
                            ...category,
                            parentCategoryId: Number(e.target.value),
                          })
                        }
                        variant='outlined'
                        fullWidth
                      >
                        <MenuItem value=''>
                          <em>Select Parent Category</em>
                        </MenuItem>
                        {categories.map((parentCategory) => (
                          <MenuItem
                            key={parentCategory.id}
                            value={parentCategory.id}
                          >
                            {parentCategory.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color='secondary'
                        onClick={() => deleteCategory(category.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});
