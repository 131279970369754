// Catalog.tsx
import { Grid } from "@mui/material";
import MenuItemList from "./MenuItemList";
import { store, useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import MenuItemOptionsBar from "./MenuItemOptionsBar";
import { useEffect, useState } from "react";

export default observer(function Catalog() {
  const { catalogStore } = useStore();
  const { menuItems } = catalogStore;
  const [isScrolled, setIsScrolled] = useState(false);

  const checkScrollPosition = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  function customSort(a: any, b: any) {
    if (a.category === b.category) {
      if (a.subCategory === b.subCategory) {
        return a.name.localeCompare(b.name);
      }
      return a.subCategory.localeCompare(b.subCategory);
    }
    return a.category.localeCompare(b.category);
  }

  const sortedMenuItems = Array.from(menuItems.values()).sort(
    (a, b) => a.displayOrder - b.displayOrder
  );

  async function scrollToCategory(categoryId: string) {
    let element = document.getElementById(`category-${categoryId}`);

    // while (!element) {
    //   if (
    //     catalogStore.pagination!.currentPage >=
    //     catalogStore.pagination!.totalPages
    //   ) {
    //     break;
    //   }
    //   await catalogStore.loadMoreMenuItems();
    // element = document.getElementById(`category-${categoryId}`);
    // }

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  return (
    <>
      <Grid container width={"100%"}>
        <Grid item xs={12}>
          <Grid container>
            <MenuItemOptionsBar
              setMenuItemParams={store.catalogStore.setMenuItemParams}
              scrollToCategory={scrollToCategory}
            />
            {isScrolled && (
              <MenuItemOptionsBar
                setMenuItemParams={store.catalogStore.setMenuItemParams}
                scrollToCategory={scrollToCategory}
                isScrolled={isScrolled}
              />
            )}
          </Grid>
          <MenuItemList menuItems={Array.from(sortedMenuItems.values())} />
        </Grid>
      </Grid>
    </>
  );
});
