import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import {
  OptionChoiceDto,
  MenuItem,
  MenuItemOption,
  MenuItemOptionChoice,
} from "../../app/models/menuItem";
import {
  Typography,
  TableContainer,
  TextField,
  FormControlLabel,
  Radio,
  Checkbox,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardHeader,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState, useRef } from "react";
import { currencyFormat } from "../../app/util/util";
import { BasketItem } from "../../app/models/basket";

interface Props {
  basketItem: BasketItem;
  onQuantityChange: (itemId: string, newQuantity: number) => void;
}

export default observer(function UpdateBasketItem({
  basketItem,
  onQuantityChange,
}: Props) {
  const { catalogStore, basketStore, modalStore } = useStore();
  const { fetchMenuItem } = catalogStore;
  const [localOptions, setLocalOptions] = useState<MenuItemOption[]>([]);
  const selectedChoicesRef = useRef<MenuItemOptionChoice[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const menuItemId = basketItem.menuItemId!;
  const menuItem = catalogStore.menuItems.get(menuItemId!) as MenuItem;

  const [quantity, setQuantity] = useState(basketItem?.quantity || 1);

  const selectedOptionPrice = localOptions.reduce((total, option) => {
    const selectedChoices = option.choices.filter((choice) => choice.selected);
    const choicePrices = selectedChoices.map((choice) => choice.price);
    const optionPrice = choicePrices.reduce((a, b) => a + b, 0);
    return total + optionPrice;
  }, 0);

  function handleInputChange(e: any) {
    const newQuantity = parseInt(e.target.value);
    setQuantity(newQuantity);
  }

  function handleUpdateCart() {
    const selectedChoicesWithOptionId = localOptions.flatMap(
      (option: MenuItemOption) => {
        return option.choices
          .filter((choice: MenuItemOptionChoice) => choice.selected)
          .map((choice: MenuItemOptionChoice) => ({
            ...choice,
            optionId: option.id,
          }));
      }
    );

    const selectedOptions: OptionChoiceDto[] = selectedChoicesWithOptionId.map(
      (choice) => ({
        optionId: choice.optionId,
        choiceId: choice.id,
      })
    );

    basketItem &&
      basketStore.updateItem(
        basketItem.id,
        basketItem.menuItemId,
        quantity,
        selectedOptions
      );

    onQuantityChange(basketItem.id + "-" + basketItem.menuItemId, quantity);
    modalStore.closeModal();
  }

  function setQuantityHandler(value: number) {
    let newQuantity = value >= 0 ? value : 0;
    setQuantity(newQuantity);
  }

  function handleChoiceChange(optionId: number, choiceId: number) {
    const updatedOptions = updateSelectedChoices(
      localOptions,
      optionId,
      choiceId
    );
    catalogStore.selectedChoices = updatedOptions
      .flatMap((option: MenuItemOption) => option.choices)
      .filter((choice: MenuItemOptionChoice) => choice.selected);
    setLocalOptions(updatedOptions);
    selectedChoicesRef.current = catalogStore.selectedChoices;
  }

  function updateSelectedChoices(
    localOptions: MenuItemOption[],
    optionId: number,
    choiceId: number
  ) {
    const updatedOptions = JSON.parse(JSON.stringify(localOptions));
    const optionToUpdate = updatedOptions.find(
      (option: MenuItemOption) => option.id === optionId
    );

    if (optionToUpdate) {
      const updateChoice = (choice: MenuItemOptionChoice) => {
        if (optionToUpdate.required) {
          choice.selected = choice.id === choiceId;
        } else {
          choice.selected =
            choice.id === choiceId ? !choice.selected : choice.selected;
        }
      };
      optionToUpdate.choices.forEach(updateChoice);
    }
    return updatedOptions;
  }

  useEffect(() => {
    if (menuItem && menuItem.options && localOptions.length === 0) {
      const copiedOptions = JSON.parse(JSON.stringify(menuItem.options));
      const initialSelectedChoiceIds =
        basketItem?.optionChoices.map((oc) => oc.id) || [];
      copiedOptions.forEach((option: MenuItemOption) => {
        option.choices.forEach((choice: MenuItemOptionChoice) => {
          if (initialSelectedChoiceIds.includes(choice.id)) {
            choice.selected = true;
          } else if (option.required) {
            choice.selected = false;
          }
        });
      });
      setLocalOptions(copiedOptions);
    }
  }, [menuItem, localOptions, basketItem, fetchMenuItem, menuItemId]);

  return (
    <>
      {menuItem && (
        <Grid
          container
          sx={{
            maxHeight: isMobile ? "calc(80vh - 20rem)" : "calc(80vh - 20rem)",
            zIndex: 999,
            overflow: "auto",
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              {localOptions.map((option) => (
                <Grid item xs={12} md={12} lg={12} key={option.id}>
                  <Card>
                    <CardHeader
                      title={option.name}
                      sx={{
                        fontSize: isMobile ? "0.75rem" : "0.5rem",
                      }}
                    />
                    <CardContent sx={{}}>
                      <TableContainer>
                        <Table
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none",
                            },
                          }}
                        >
                          <TableBody>
                            {option.choices.map((choice) => (
                              <TableRow key={choice.id} sx={{}}>
                                <TableCell>
                                  {option.required ? (
                                    <FormControlLabel
                                      value={choice.id}
                                      label={`${choice.name} +${currencyFormat(
                                        choice.price
                                      )}`}
                                      control={
                                        <Radio
                                          name={option.id.toString()}
                                          value={choice.id}
                                          sx={{ color: "primary.main" }}
                                          checked={choice.selected}
                                          onChange={(event) =>
                                            handleChoiceChange(
                                              option.id,
                                              choice.id
                                            )
                                          }
                                        />
                                      }
                                    />
                                  ) : (
                                    <FormControlLabel
                                      value={choice.id}
                                      label={`${choice.name} +${currencyFormat(
                                        choice.price
                                      )}`}
                                      control={
                                        <Checkbox
                                          name={option.id.toString()}
                                          value={choice.id}
                                          sx={{ color: "primary.main" }}
                                          checked={choice.selected}
                                          onChange={(event) =>
                                            handleChoiceChange(
                                              option.id,
                                              choice.id
                                            )
                                          }
                                        />
                                      }
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        sx={{
          position: "sticky",
          bottom: 0,
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{}} elevation={0}>
            <CardContent>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h6">Quantity</Typography>
                </Grid>
                <CardContent>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item container alignItems="center">
                      <Grid item>
                        <RemoveIcon
                          onClick={() => setQuantityHandler(quantity - 1)}
                          sx={{ cursor: "pointer" }}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{ width: "50px", textAlign: "center" }}
                          value={quantity}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Grid>
                      <Grid item>
                        <AddIcon
                          onClick={() => setQuantityHandler(quantity + 1)}
                          sx={{ cursor: "pointer" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <LoadingButton
                  onClick={() => handleUpdateCart()}
                  sx={{ width: "100%" }}
                  variant="contained"
                  size="large"
                  loading={
                    basketStore.status === `pendingUpdateItem${menuItemId}`
                  }
                >
                  {quantity > 0
                    ? `${currencyFormat(
                        menuItem.price * quantity +
                          selectedOptionPrice * quantity
                      )} Update items in the cart`
                    : "Zero items in the cart"}
                </LoadingButton>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
});
