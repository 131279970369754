import { LockOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import ContentContainer from "../../app/common/container/ContentContainer";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

interface ResetPasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors, isValid },
    watch,
  } = useForm<ResetPasswordFormValues>();
  const { theme: customTheme } = useCustomTheme();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const email = params.get("email");

  const newPassword = watch("newPassword");

  function handleApiErrors(error: any) {
    console.log(error.data);
    if (error.data) {
      let errorMessage = error.data;

      if (errorMessage.includes("Password")) {
        setError("newPassword", { message: errorMessage });
      }
    }
  }

  const onSubmit = (data: ResetPasswordFormValues) => {
    const resetPasswordFormValues = {
      newPassword: data.newPassword,
      confirmNewPassword: data.confirmNewPassword,
    };
    const { newPassword, confirmNewPassword } = resetPasswordFormValues;

    agent.Account.resetPassword(email!, token!, newPassword, confirmNewPassword)
      .then(() => {
        toast.success("Password reset successful - you can now login");
        navigate("/login");
      })
      .catch((error: any) => handleApiErrors(error));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContentContainer>
      <Grid
        container
        alignItems='center'
        sx={{ sm: { m: 5 }, md: { m: 5 } }}
        px={{ xs: 2, sm: 20, md: 30 }}
      >
        <Grid item xs={12}>
          <Typography
            variant='h6'
            align='center'
            sx={{
              mt: { xs: "4rem" },
              fontWeight: 500,
            }}
          >
            Reset Password
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 5 }}
        >
          <InputLabel>New Password</InputLabel>
          <TextField
            required
            fullWidth
            type='password'
            {...register("newPassword", {
              required: "New password is required",
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                message: "Password does not meet complexity requirements",
              },
            })}
            error={!!errors.newPassword}
            helperText={errors?.newPassword?.message as string}
            sx={{
              mb: 1,
              "& .MuiInputBase-input": {
                borderRadius: "30px",
                backgroundColor: "#e3e3e3",
                height: "1px",
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                "& fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3",
                },
              },
            }}
          />
          <InputLabel>Confirm New Password</InputLabel>
          <TextField
            required
            fullWidth
            type='password'
            {...register("confirmNewPassword", {
              required: "Confirm password is required",
              validate: (value) =>
                value === newPassword || "The passwords do not match",
            })}
            error={!!errors.confirmNewPassword}
            helperText={errors?.confirmNewPassword?.message as string}
            sx={{
              mb: 1,
              "& .MuiInputBase-input": {
                borderRadius: "30px",
                backgroundColor: "#e3e3e3",
                height: "1px",
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                "& fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3",
                },
              },
            }}
          />
          <Grid xs={12} mt={4}>
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              loading={isSubmitting}
              loadingPosition={isMobile ? "center" : "start"}
              disabled={!isValid || isSubmitting}
              sx={{
                display: "flex",
                justifyContent: "center",
                my: 2,
                margin: "auto",
                bgcolor: customTheme.primaryColor,
                "&:hover": { bgcolor: customTheme.primaryHoverColor },
                borderRadius: "30px",
                textTransform: "capitalize",
                boxShadow: "none",
                width: "70%",
              }}
            >
              Reset Password
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}
