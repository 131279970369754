import { CssBaseline } from "@mui/material";
import CatalogMenu from "../catalog/homeMenu/CatalogMenu";
import NavBar from "../../app/layout/NavBar";
import LocationInfo from "./LocationInfo";
import RestaurantHeader from "./RestaurantHeader";
import PlaceAnOrderBtnSection from "./PlaceAnOrderBtnSection";

function App() {
  const handleScrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <NavBar />
      <CssBaseline />
      <RestaurantHeader />
      <CatalogMenu />
      <LocationInfo />
      <PlaceAnOrderBtnSection />
    </>
  );
}

export default App;
