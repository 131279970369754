import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import BasketTable from "./BasketTable";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { router } from "../../app/router/Routes";
import { useEffect, useState } from "react";
import ContentContainer from "../../app/common/container/ContentContainer";
import { ArrowBackIos, Email, FacebookOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

export default observer(function BasketPage() {
  const { basketStore, userStore } = useStore();
  const { user } = userStore;
  const { getTotal, ItemCountTotal } = basketStore;
  const [Gratuity, setGratuity] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const theme = useTheme();
  const { theme: customTheme } = useCustomTheme();

  useEffect(() => {
    if (ItemCountTotal === 0) {
      router.navigate(`${process.env.PUBLIC_URL}/`);
    }

    (getTotal && getTotal >= 100) || getTotal === 0
      ? setGratuity(0)
      : setGratuity(15);
  }, [Gratuity, getTotal, ItemCountTotal]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (getTotal === 0) {
    return <Typography variant='h3'></Typography>;
  }

  function handleGoogleLogin() {
    window.location.href =
      process.env.REACT_APP_API_URL + "/auth/google-signin";
  }

  function handleFacebookLogin() {
    window.location.href = process.env.REACT_APP_API_URL + "/auth/fb-signin";
  }

  return (
    <ContentContainer>
      <Grid
        container
        alignItems='center'
        sx={{ sm: { m: 5 }, md: { m: 5 } }}
        px={{ xs: 0, sm: 10, md: 10 }}
      >
        <Grid item>
          <IconButton
            component={Link}
            to='/'
            edge='start'
            sx={{
              paddingLeft: 2,
              textTransform: "none",
              color: customTheme.primaryColor,
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: customTheme.primaryHoverColor,
              },
            }}
          >
            <ArrowBackIos sx={{ fontSize: { sm: "1rem", md: "2rem" } }} />
            <Typography variant={theme.breakpoints.down("sm") ? "h5" : "h5"}>
              Back To Main
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h4'
            align='center'
            gutterBottom
            sx={{
              flexGrow: 1,
              fontWeight: 500,
              fontSize: { xs: "1.75rem", sm: "1.75rem" },
            }}
          >
            Item Cart
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems='center' px={{ xs: 0, sm: 10, md: 10 }}>
        <Grid item xs={12}>
          <BasketTable />
        </Grid>
      </Grid>

      {user ? (
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          sx={{
            height: 100,
            mb: 5,
          }}
        >
          <Grid item>
            <LoadingButton
              variant='contained'
              sx={{
                bgcolor: customTheme.primaryColor,
                "&:hover": { bgcolor: customTheme.primaryHoverColor },
                borderRadius: "20px",
                textTransform: "capitalize",
                boxShadow: "none",
                color: "#fff !important",
                paddingInline: "4rem",
              }}
              size='large'
              component={Link}
              to='/checkout'
            >
              Checkout
            </LoadingButton>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          my={10}
          px={{ xs: 0, sm: 15, md: 30 }}
        >
          <Grid container justifyContent='center' alignItems='center'>
            <Typography variant={"h5"} mb={2}>
              Sign in for Checkout
            </Typography>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                sx={{
                  my: 1,
                  borderRadius: "30px",
                  width: "100%",
                  bgcolor: "#4285F6",
                  "&:hover": { bgcolor: "#1C6FD6" },
                }}
                onClick={handleGoogleLogin}
              >
                <Box
                  component='span'
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar
                    src={`${process.env.PUBLIC_URL}/assets/signinButtons/g-logo.png`}
                    alt='Google Icon'
                    sx={{ marginRight: 1, width: "20px", height: "20px" }}
                  />
                  Continue with Google
                </Box>
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                sx={{
                  my: 1,
                  borderRadius: "30px",
                  width: "100%",
                  bgcolor: "#3B5998",
                  "&:hover": { bgcolor: "#203D70" },
                }}
                onClick={handleFacebookLogin}
              >
                <Box
                  component='span'
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FacebookOutlined sx={{ marginRight: 1 }} />
                  Continue with Facebook
                </Box>
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                <Box sx={{ flexGrow: 1, borderTop: "2px solid #e3e3e3" }} />
                <Typography
                  variant='body2'
                  component='span'
                  color='textSecondary'
                  sx={{ px: 1 }}
                >
                  or continue with
                </Typography>
                <Box sx={{ flexGrow: 1, borderTop: "2px solid #e3e3e3" }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                sx={{
                  my: 1,
                  borderRadius: "30px",
                  width: "100%",
                  bgcolor: "#000",
                  "&:hover": { bgcolor: "#555" },
                }}
                onClick={() =>
                  router.navigate(`${process.env.PUBLIC_URL}/login`)
                }
              >
                <Box
                  component='span'
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Email sx={{ marginRight: 1 }} />
                  Sign in with Email
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ContentContainer>
  );
});
