import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPassword: React.FC = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm<ForgotPasswordFormValues>({
    mode: "onTouched",
  });
  const { theme: customTheme } = useCustomTheme();

  const emailPattern = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: "Invalid email address",
  };

  const { userStore, modalStore } = useStore();
  const { sendResetPasswordEmail } = userStore;

  const submitForm = async (data: FieldValues) => {
    try {
      const formData: ForgotPasswordFormValues = {
        email: data.email,
      };
      const result = await sendResetPasswordEmail(formData);
      modalStore.openModal(<EmailSentModal success={result} />);
    } catch (error) {
      console.log(error);
    }
  };

  interface EmailSentModalProps {
    success: boolean;
  }

  const EmailSentModal = ({ success }: EmailSentModalProps) => {
    return (
      <Container
        maxWidth='sm'
        sx={{
          p: 2,
          width: "20rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant='h4' sx={{ mb: 2 }}>
          {success ? "Email sent!" : "Error"}
        </Typography>
        <Typography variant='subtitle1' sx={{ textAlign: "center", mb: 4 }}>
          {success
            ? "Check your email to reset password"
            : "Failed to send reset password email. Please try again."}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant='contained'
            onClick={() => modalStore.closeModal()}
            sx={{
              width: "50%",
              bgcolor: customTheme.primaryColor,
              "&:hover": { bgcolor: customTheme.primaryHoverColor },
              borderRadius: "30px",
              textTransform: "capitalize",
            }}
          >
            Close
          </Button>
        </Box>
      </Container>
    );
  };

  return (
    <Container
      maxWidth='sm'
      sx={{
        p: 2,
        width: "20rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component='h1' variant='h4'>
        Find password
      </Typography>
      <Box
        component='form'
        onSubmit={handleSubmit(submitForm)}
        noValidate
        sx={{ mt: 2 }}
      >
        <Typography variant='body1' sx={{ mb: 2, textAlign: "center" }}>
          Reset password email will be sent to your email address
        </Typography>

        <TextField
          required
          fullWidth
          autoFocus
          placeholder='Enter your email'
          {...register("email", {
            required: "Email is required",
            pattern: emailPattern,
          })}
          error={!!errors.email}
          helperText={errors?.email?.message as string}
          sx={{
            mb: 4,
            "& .MuiInputBase-input": {
              borderRadius: "30px",
              backgroundColor: "#e3e3e3",
              height: "1px",
            },
            "& .MuiInputLabel-root": {
              color: "#A0AAB4",
            },
            "& .MuiInputLabel-shrink": {
              color: "#A0AAB4",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "30px",
              "& fieldset": {
                borderColor: "#e3e3e3",
              },
              "&:hover fieldset": {
                borderColor: "#e3e3e3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#e3e3e3",
              },
            },
          }}
        />

        <LoadingButton
          disabled={!isValid}
          loading={isSubmitting}
          type='submit'
          variant='contained'
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 10,
            margin: "auto",
            width: "70%",
            bgcolor: customTheme.primaryColor,
            "&:hover": { bgcolor: customTheme.primaryHoverColor },
            borderRadius: "30px",
            textTransform: "capitalize",
            boxShadow: "none",
          }}
        >
          Next
        </LoadingButton>
      </Box>
    </Container>
  );
});

export default ForgotPassword;
