import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { TextField, Button, FormControlLabel, Checkbox, Grid, Paper } from '@mui/material';
import { useStore } from '../../app/stores/store';
import { UserForAdminControls } from '../../app/models/user';
import { set } from 'lodash';

interface UserDetailProps {
    selectedUser: UserForAdminControls | null;
  }
  
export default observer(function UserDetail( {selectedUser} : UserDetailProps) {
    const { adminStore } = useStore();
    const { user, loadUser, updateUser, deleteUser } = adminStore;
    const [userToUpdate, setUserToUpdate] = useState<UserForAdminControls | null>(null);
    const {modalStore} = useStore();

    useEffect(() => {
      setUserToUpdate(selectedUser);
    }, [loadUser, selectedUser]);

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (userToUpdate) {
            const newUser = {...userToUpdate, roles: userToUpdate.roles.includes(event.target.name) ? 
                userToUpdate.roles.filter(role => role !== event.target.name) : [...userToUpdate.roles, event.target.name] };
            setUserToUpdate(newUser);
        }
    };

    const handleUpdateUser = () => {
        if (userToUpdate) { 
            updateUser(userToUpdate);
        }
    };

    const handleDeleteUser = () => {
        if (userToUpdate) { 
            deleteUser(userToUpdate.id).then(() => {
                modalStore.closeModal();
            });
        }
    };

    return userToUpdate ? (
        <Grid justifyContent="center" >
            <Grid item xs={12} sm={8} md={6}>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Email" value={userToUpdate.email} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Address" value={userToUpdate.address} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Phone Number" value={userToUpdate.phoneNumber} />
                            </Grid>
                            <Grid item xs={12}>
                            {userToUpdate && userToUpdate.roles && (
        <>
                                <FormControlLabel 
                                    control={
                                      <Checkbox 
                                        checked={userToUpdate.roles.includes("Admin")} 
                                        onChange={handleRoleChange} 
                                        name="Admin" 
                                      />
                                    }
                                    label="Admin"
                                />
                                <FormControlLabel 
                                    control={
                                      <Checkbox 
                                        checked={userToUpdate.roles.includes("Manager")} 
                                        onChange={handleRoleChange} 
                                        name="Manager" 
                                      />
                                    }
                                    label="Manager"
                                />
                                <FormControlLabel 
                                    control={
                                      <Checkbox 
                                        checked={userToUpdate.roles.includes("Member")} 
                                        onChange={handleRoleChange} 
                                        name="Member" 
                                      />
                                    }
                                    label="Member"
                                />  
                                <FormControlLabel 
                                    control={
                                      <Checkbox 
                                        checked={userToUpdate.roles.includes("User")} 
                                        onChange={handleRoleChange} 
                                        name="User" 
                                      />
                                    }
                                    label="User"
                                />   
                                </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUpdateUser}
                                >
                                    Update
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleDeleteUser}

                                >
                                    Delete
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
            </Grid>
        </Grid>
    ) : null;
});
