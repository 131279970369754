import NavBar from "./NavBar";
import { observer } from "mobx-react-lite";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import { ToastContainer } from "react-toastify";
import { store, useStore } from "../stores/store";
import { useCallback, useEffect } from "react";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import { Container } from "@mui/material";

function App() {
  const location = useLocation();
  const { commonStore, userStore } = useStore();

  const initApp = useCallback(async () => {
    try {
      await store.userStore.fetchCurrentUser();
      await store.basketStore.fetchBasket();
      await store.categoryStore.loadCategories();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    initApp().then(() => commonStore.setAppLoaded());
  }, [initApp, commonStore]);

  if (!commonStore.appLoaded) return <LoadingComponent />;

  return (
    <>
      <ScrollRestoration />
      <ModalContainer />
      <ToastContainer position='bottom-right' hideProgressBar theme='colored' />
      {location.pathname === "/" ? (
        <HomePage />
      ) : (
        <>
          <NavBar />
          <Container sx={{ marginTop: { xs: 5, sm: 15 } }}>
            <Outlet />
          </Container>
        </>
      )}
    </>
  );
}

export default observer(App);
