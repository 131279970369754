import React from "react";
import { Button, Typography, Container } from "@mui/material";
import { router } from "../../app/router/Routes";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

const RegisterSuccessPage: React.FC = () => {
  const { theme: customTheme } = useCustomTheme();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5em",
        height: "70vh",
      }}
    >
      <Typography
        component='h1'
        variant='h6'
        sx={{
          fontSize: { xs: "1rem", sm: "2rem", md: "2rem" },
        }}
      >
        You have successfully signed up ! 😊
      </Typography>
      <Typography
        component='h1'
        variant='body1'
        sx={{
          fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
        }}
      >
        Let’s go and grab something to eat
      </Typography>

      <Button
        variant='contained'
        size='large'
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
          width: { xs: "10rem", sm: "15rem", md: "20rem" },
          bgcolor: customTheme.primaryColor,
          "&:hover": { bgcolor: customTheme.primaryHoverColor },
          borderRadius: "30px",
          textTransform: "capitalize",
          boxShadow: "none",
        }}
        onClick={() => {
          router.navigate(`${process.env.PUBLIC_URL}/login`);
        }}
      >
        Log In to Checkout
      </Button>
    </Container>
  );
};

export default RegisterSuccessPage;
