import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Category } from "../models/category";
import { store } from "./store";

export default class CategoryStore {
  categoryRegistry = new Map<number, Category>();
  selectedCategory: Category | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  get categories() {
    return Array.from(this.categoryRegistry.values());
  }

  loadCategories = async () => {
    this.loadingInitial = true;
    try {
      const categories = await agent.Category.list();
      runInAction(() => {
        categories.forEach((category: Category) => {
          this.categoryRegistry.set(category.id, category);
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadCategory = async (id: number) => {
    let category = this.getCategory(id);
    if (category) {
      this.selectedCategory = category;
      return category;
    } else {
      this.loadingInitial = true;
      try {
        category = await agent.Category.details(id);
        runInAction(() => {
          this.selectedCategory = category;
          this.loadingInitial = false;
        });
        return category;
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.loadingInitial = false;
        });
      }
    }
  };

  private getCategory = (id: number) => {
    return this.categoryRegistry.get(id);
  };

  createCategory = async (category: Category) => {
    console.log("store", category);
    this.loading = true;
    try {
      await agent.Category.create(category);
      runInAction(() => {
        this.categoryRegistry.set(category.id, category);
        this.selectedCategory = category;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateCategory = async (category: Category) => {
    this.loading = true;
    try {
      await agent.Category.update(category);
      runInAction(() => {
        this.categoryRegistry.set(category.id, category);
        this.selectedCategory = category;
        this.editMode = false;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    } finally {
      this.loadCategories();
    }
  };

  deleteCategory = async (id: number) => {
    this.loading = true;
    try {
      await agent.Category.delete(id);
      runInAction(() => {
        this.categoryRegistry.delete(id);
        if (this.selectedCategory?.id === id) this.cancelSelectedCategory();
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  openForm = (id?: number) => {
    id ? this.loadCategory(id) : this.cancelSelectedCategory();
    this.editMode = true;
  };

  closeForm = () => {
    this.editMode = false;
  };

  cancelSelectedCategory = () => {
    this.selectedCategory = undefined;
  };
}
