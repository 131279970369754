import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export default function MenuItemCardSkeleton() {
  return (
    <Card
      sx={{
        display: "flex",
        marginY: 1,
        flexBasis: "100%",
        width: "100%",
      }}
    >
      <CardMedia
        component={() => (
          <Skeleton
            variant='rectangular'
            sx={{
              width: "100px",
              height: "100px",
              margin: "1rem 1rem",
              borderRadius: "4px",
            }}
          />
        )}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          maxWidth: "calc(100% - 160px)",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            variant='h6'
            color='primary.main'
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            <Skeleton width='80%' />
          </Typography>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ marginBottom: 1 }}
          >
            <Skeleton width='60%' />
          </Typography>
          <Typography gutterBottom color='secondary' variant='h5'>
            <Skeleton width='40%' />
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}
