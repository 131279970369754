import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  useTheme,
  Paper,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import PaymentForm from "./PaymentForm";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import agent from "../../app/api/agent";
import { useStore } from "../../app/stores/store";
import BasketSummary from "../basket/BasketSummary";
import { Link } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

interface BusinessHours {
  [key: string]: string[];
}

const CheckoutDetails = () => {
  const [loading, setLoading] = useState(false);
  const [cardState, setCardState] = useState<{
    elementError: { [key: string]: string | undefined };
  }>({ elementError: {} });
  const [tip, setTip] = useState("5%");
  const [customTip, setCustomTip] = useState("");
  const [dateWarning, setDateWarning] = useState("");

  const theme = useTheme();
  const navigate = useNavigate();
  const { theme: customTheme } = useCustomTheme();

  const { basketStore } = useStore();
  const { basket, clearBasket } = basketStore;

  const businessHours: BusinessHours = {
    Sun: ["11:30", "15:00", "17:00", "21:30"],
    Mon: ["11:30", "15:00", "17:00", "21:30"],
    Tue: ["17:00", "21:30"],
    Wed: ["11:30", "15:00", "17:00", "21:30"],
    Thu: ["11:30", "15:00", "17:00", "21:30"],
    Fri: ["11:30", "15:00", "17:00", "22:30"],
    Sat: ["11:30", "15:00", "17:00", "22:30"],
  };

  const isTimeWithinBusinessHours = (time: any, date: any) => {
    const day = date.toLocaleDateString("en-US", { weekday: "short" });
    const hours = businessHours[day];
    if (!hours) return false;

    const timeObj = time instanceof Date ? time : new Date(time);

    const currentTimeInMinutes = timeObj.getHours() * 60 + timeObj.getMinutes();

    for (let i = 0; i < hours.length; i += 2) {
      const startTime = convertTimeToMinutes(hours[i]);
      const endTime = convertTimeToMinutes(hours[i + 1]);

      if (currentTimeInMinutes >= startTime && currentTimeInMinutes < endTime) {
        return true;
      }
    }
    return false;
  };

  const convertTimeToMinutes = (time: any) => {
    const [hour, minute] = time.split(":").map(Number);
    return hour * 60 + minute;
  };

  const getDefaultDateTime = () => {
    let now = new Date();
    let adjusted = false;

    while (true) {
      const day = now.toLocaleDateString("en-US", { weekday: "short" });
      const hours = businessHours[day];

      if (!hours) {
        now.setDate(now.getDate() + 1);
        now.setHours(0, 0, 0, 0);
        continue;
      }

      const currentTime = `${now.getHours().toString().padStart(2, "0")}:${now
        .getMinutes()
        .toString()
        .padStart(2, "0")}:00`;

      for (let i = 0; i < hours.length; i += 2) {
        const startTime = hours[i];
        const endTime = hours[i + 1];

        if (currentTime >= startTime && currentTime < endTime) {
          if (!adjusted) {
            now.setMinutes(now.getMinutes() + 30);
            adjusted = true;
          }
          return now;
        }

        const [startHour, startMinute] = startTime.split(":").map(Number);
        if (currentTime < startTime) {
          now.setHours(startHour, startMinute, 0, 0);
          return now;
        }
      }

      now.setDate(now.getDate() + 1);
      now.setHours(0, 0, 0, 0);
    }
  };

  const methods = useForm({
    defaultValues: {
      pickupDateTime: getDefaultDateTime(),
      nameOnCard: "",
    },
  });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const initialTipPercentage = parseFloat(tip.replace("%", ""));
    const initialGratuity = (basketStore.getTotal * initialTipPercentage) / 100;
    basketStore.setGratuity(initialGratuity);
    agent.Payments.updatePaymentIntent(initialGratuity);
  }, []);

  const [cardFieldsFilled, setCardFieldsFilled] = useState({
    cardNumberFilled: false,
    cardExpiryFilled: false,
    cardCvcFilled: false,
  });

  const handleCardInputChange = (event: any) => {
    const { elementType, error, empty, complete } = event;

    setCardState((prevState) => ({
      ...prevState,
      elementError: {
        ...prevState.elementError,
        [elementType]: error ? error.message : undefined,
      },
    }));

    setCardFieldsFilled((prevFields) => ({
      ...prevFields,
      [`${elementType}Filled`]: !empty || complete,
    }));
  };

  const handleTipChange = async (event: any) => {
    const { value } = event.target;
    setTip(value);
    const calculatedGratuity =
      value === "Custom" ? parseFloat(customTip) || 0 : calculateTip(value);
    basketStore.setGratuity(calculatedGratuity);

    const { gratuity } = basketStore.getTotalDetails();

    try {
      await agent.Payments.updatePaymentIntent(gratuity);
    } catch (error) {
      console.error("Failed to update PaymentIntent:", error);
    }
  };

  const handleCustomTipChange = async (event: any) => {
    const inputValue = event.target.value;
    setCustomTip(inputValue);
    setTip("Custom");
    const gratuity = parseFloat(inputValue) || 0;
    basketStore.setGratuity(gratuity);

    try {
      await agent.Payments.updatePaymentIntent(gratuity);
    } catch (error) {
      console.error("Failed to update PaymentIntent:", error);
    }
  };

  const calculateTip = (tipValue: string) => {
    const basketTotal = basketStore.getTotal;
    const percentage = parseFloat(tipValue.replace("%", ""));
    return (basketTotal * percentage) / 100;
  };

  const handleSubmit = methods.handleSubmit(async (data) => {
    const orderData = {
      gratuity: basketStore.gratuity,
      pickupDateTime: data.pickupDateTime || new Date(),
    };

    setLoading(true);

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet!");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      console.error("Card element not found");
      setLoading(false);
      return;
    }

    try {
      const paymentResult = await stripe.confirmCardPayment(
        basket?.clientSecret!,
        {
          payment_method: {
            card: cardElement!,
            billing_details: {
              name: data.nameOnCard,
            },
          },
        }
      );

      if (paymentResult.error) {
        console.error(paymentResult.error.message);
      } else if (
        paymentResult.paymentIntent &&
        paymentResult.paymentIntent.status === "succeeded"
      ) {
        const response = await agent.Orders.create(orderData);

        const orderNum = Number(response);
        clearBasket();
        setLoading(false);
        navigate("/checkout-result", { state: { success: orderNum > 0 } });
      } else {
        setLoading(false);
        navigate("/checkout-result", { state: { success: false } });
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }

    setLoading(false);
  });

  const canSubmit = (() => {
    const pickupDateTimeValid = isTimeWithinBusinessHours(
      methods.watch("pickupDateTime"),
      methods.watch("pickupDateTime")
    );
    const nameOnCardValid = !!methods.watch("nameOnCard");
    const cardNumberFilled = cardFieldsFilled.cardNumberFilled;
    const cardExpiryFilled = cardFieldsFilled.cardExpiryFilled;
    const cardCvcFilled = cardFieldsFilled.cardCvcFilled;
    const noCardErrors = Object.values(cardState.elementError).every(
      (error) => !error
    );

    return (
      pickupDateTimeValid &&
      nameOnCardValid &&
      !loading &&
      !!stripe &&
      !!elements &&
      cardNumberFilled &&
      cardExpiryFilled &&
      cardCvcFilled &&
      noCardErrors &&
      !dateWarning
    );
  })();

  const handleDateTimeChange = (newValue: any) => {
    const newDate = new Date(newValue);
    const day = newDate.toLocaleDateString("en-US", { weekday: "short" });
    const hours = businessHours[day];
    methods.setValue("pickupDateTime", newDate);

    if (!hours) {
      setDateWarning(`No business hours on ${day}. Please select another day.`);
      return;
    }

    if (!isTimeWithinBusinessHours(newDate, newDate)) {
      let formattedHours = hours.reduce((acc, time, index) => {
        return (
          acc +
          (index % 2 === 0
            ? time + " to "
            : time + (index < hours.length - 1 ? ", " : ""))
        );
      }, "");

      setDateWarning(
        `Available hours for ${day} are ${formattedHours}. Please select a valid time.`
      );
    } else {
      setDateWarning("");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormProvider {...methods}>
        <Box sx={{ paddingTop: 4, paddingBottom: 4 }}>
          <IconButton
            component={Link}
            to='/basket'
            edge='start'
            sx={{
              paddingLeft: 2,
              textTransform: "none",
              color: customTheme.primaryColor,
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: customTheme.primaryHoverColor,
              },
            }}
          >
            <ArrowBackIos sx={{ fontSize: { sm: "1rem", md: "2rem" } }} />
            <Typography variant={theme.breakpoints.down("sm") ? "h5" : "h5"}>
              Back to Cart
            </Typography>
          </IconButton>
          <Typography
            variant='h5'
            align='center'
            gutterBottom
            sx={{
              flexGrow: 1,
              fontWeight: 500,
              fontSize: { xs: "1.25rem", sm: "1.75rem" },
              mt: 2,
            }}
          >
            Checkout Details
          </Typography>
          <Paper
            elevation={3}
            sx={{
              padding: 1,
              margin: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: "medium",
                      color: customTheme.primaryColor,
                      fontSize: { xs: "1rem", sm: "1.5rem" },
                    }}
                  >
                    Pickup Date and Time
                  </Typography>
                  <DateTimePicker
                    label='Pickup Date and Time'
                    value={methods.watch("pickupDateTime")}
                    onChange={handleDateTimeChange}
                    minDateTime={new Date()}
                    // shouldDisableDate={shouldDisableDate}
                    // shouldDisableTime={shouldDisableTime}
                    slotProps={{ textField: { fullWidth: true } }}
                    ampm={true}
                  />
                  {dateWarning && (
                    <Typography color='error' align='center'>
                      {dateWarning}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: "medium",
                      color: customTheme.primaryColor,
                      fontSize: { xs: "1rem", sm: "1.5rem" },
                    }}
                  >
                    Card Details
                  </Typography>
                  <PaymentForm
                    cardState={cardState}
                    onCardInputChange={handleCardInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: "medium",
                      color: customTheme.primaryColor,
                      fontSize: { xs: "1rem", sm: "1.5rem" },
                    }}
                  >
                    Add a Tip
                  </Typography>
                  <RadioGroup row value={tip} onChange={handleTipChange}>
                    <FormControlLabel
                      value='5%'
                      control={<Radio />}
                      label='5%'
                    />
                    <FormControlLabel
                      value='8%'
                      control={<Radio />}
                      label='8%'
                    />
                    <FormControlLabel
                      value='10%'
                      control={<Radio />}
                      label='10%'
                    />
                    <FormControlLabel
                      value='Custom'
                      control={<Radio />}
                      label='Custom:'
                    />
                    {tip === "Custom" && (
                      <TextField
                        type='number'
                        inputProps={{ step: "0.01" }}
                        value={customTip}
                        onChange={handleCustomTipChange}
                      />
                    )}
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: "medium",
                      color: customTheme.primaryColor,
                      fontSize: { xs: "1rem", sm: "1.5rem" },
                    }}
                  >
                    Order Summary
                  </Typography>
                  <BasketSummary />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Grid container justifyContent='center' sx={{ mt: 4 }}>
            <Button
              fullWidth
              variant='contained'
              size='large'
              onClick={handleSubmit}
              disabled={!canSubmit}
              sx={{
                bgcolor: customTheme.primaryColor,
                "&:hover": { bgcolor: customTheme.primaryHoverColor },
                borderRadius: "30px",
                textTransform: "capitalize",
                boxShadow: "none",
                width: "10rem",
                justifyContent: "center",
              }}
            >
              {loading ? "Processing..." : "Place an Order"}
            </Button>
          </Grid>
        </Box>
      </FormProvider>
    </LocalizationProvider>
  );
};

export default CheckoutDetails;
