import React, { useState } from "react";
import {
  AppBar,
  Grid,
  IconButton,
  InputBase,
  Toolbar,
  useMediaQuery,
  Tab,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TabContext, TabList } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { store } from "../../app/stores/store";

interface Props {
  setMenuItemParams: any;
  scrollToCategory: (categoryId: string) => void;
  isScrolled?: boolean;
}

export default function MenuItemOptionsBar({
  setMenuItemParams,
  scrollToCategory,
  isScrolled,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedTab, setSelectedTab] = useState("1");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchText(event.target.value);
    // onFilterChange({ search: event.target.value });
    setMenuItemParams({ searchTerm: event.target.value });
  };

  const handleExpandSearch = () => {
    setSearchExpanded(true);
  };

  const handleCloseSearch = () => {
    setSearchText("");
    setMenuItemParams({ searchTerm: "" });
    setSearchExpanded(false);
  };

  return (
    <AppBar position={isScrolled ? "fixed" : "relative"} sx={{ zIndex: 2 }}>
      <Toolbar>
        <Grid container justifyContent="space-around" alignItems="center">
          {searchExpanded ? (
            <Grid item xs={9}>
              <InputBase
                fullWidth
                value={searchText}
                onChange={handleSearch}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: "white",
                  borderRadius: 20,
                  transition: theme.transitions.create("width"),
                  width: "100%",
                }}
              />
            </Grid>
          ) : (
            <IconButton
              size="large"
              aria-label="search"
              onClick={handleExpandSearch}
              sx={{ backgroundColor: "white" }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {searchExpanded ? (
            <Grid item>
              <IconButton
                size="large"
                aria-label="close search"
                onClick={handleCloseSearch}
                sx={{ backgroundColor: "white" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid item xs={10}>
              <TabContext value={selectedTab}>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  textColor="inherit"
                  indicatorColor="primary"
                >
                  {Array.from(store.catalogStore.categories.values()).map(
                    (category, index) => (
                      <Tab
                        key={category.id}
                        value={category.id}
                        tabIndex={index + 1}
                        label={category.name}
                        onClick={() => {
                          // setMenuItemParams({ tags: [category.id] });
                          scrollToCategory(category.name);
                        }}
                        sx={{ color: "white" }}
                      />
                    )
                  )}
                </TabList>
              </TabContext>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
