import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { store } from "../../app/stores/store";

const ConfirmEmail = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("userId");
  const code = params.get("code");
  const { userStore } = store;
  const { setUser } = userStore;
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios
          .get(`/account/confirmEmail?userId=${userId}&code=${code}`)
          .then((response) => {
            setUser(response.data);
            localStorage.setItem("user", JSON.stringify(response.data));

            store.commonStore.setToken(response.data.token);
            localStorage.setItem("jwt", response.data.token);
            navigate("/");
          });
        // handle response here (e.g. redirect the user, show a success message, etc.)
      } catch (error) {
        // handle error here (e.g. show an error message to the user)
      }
    };

    confirmEmail();
  }, [userId, code]);

  return (
    <div>
      <h2>Confirming your email...</h2>
      {/* You might want to add some more UI here (e.g. a loading spinner) */}
    </div>
  );
};

export default ConfirmEmail;
