import { Grid, Chip, Typography, useTheme, useMediaQuery } from "@mui/material";
import MenuItemList from "./MenuItemList";
import { store, useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { MenuItem } from "../../../app/models/menuItem";
import MostLovedMenu from "./MostLovedMenu";
import ContentContainer from "../../../app/common/container/ContentContainer";
import { useTheme as useCustomTheme } from "../../../app/contexts/ThemeContext";

export default observer(function Catalog() {
  const { catalogStore, basketStore } = useStore();
  const { addItem, basket } = basketStore;
  const { menuItems } = catalogStore;
  const [selectedCatalog, setSelectedCatalog] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { theme: customTheme } = useCustomTheme();

  function customSort(a: any, b: any) {
    if (a.category === b.category) {
      if (a.subCategory === b.subCategory) {
        return a.name.localeCompare(b.name);
      }
      return a.subCategory.localeCompare(b.subCategory);
    }
    return a.category.localeCompare(b.category);
  }

  const sortedMenuItems = Array.from(menuItems.values()).sort(customSort);

  const filteredMenuItems = selectedCatalog
    ? sortedMenuItems.filter(
        (menuItem) => menuItem.category === selectedCatalog
      )
    : sortedMenuItems;

  const handleSelectCatalog = (catalog: string | null) => {
    setSelectedCatalog(catalog);
  };

  // Get the most loved menu items in ascending order of quantityInStock
  const mostLovedMenuItems = sortedMenuItems
    .filter((menuItem) => menuItem.quantityInStock > 0)
    .sort((a, b) => a.quantityInStock - b.quantityInStock)
    .slice(0, 4);

  function handleAddToCart(menuItem: MenuItem): void {
    const item =
      basket && basket.items
        ? basket.items.find((i) => i.menuItemId === menuItem?.id)
        : undefined;
    addItem(item?.id!, menuItem?.id!, 1);
  }

  return (
    <>
      {/* {!isMobile && (
        <Box
          component="img"
          src="./../assets/common/Ellipse 17.png"
          sx={{
            position: "absolute",
            top: 400,
            right: -150,
            width: { xs: "30%", sm: "30%" },
            height: "auto",
            zIndex: 1,
          }}
        />
      )} */}

      <ContentContainer>
        <Grid container justifyContent='center' sx={{ my: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant='h4'
              align='center'
              gutterBottom
              fontWeight='bold'
            >
              Menu
            </Typography>

            <Grid container spacing={2} justifyContent='center' sx={{ my: 3 }}>
              <Grid item>
                <Chip
                  label='All Menus'
                  onClick={() => handleSelectCatalog(null)}
                  sx={{
                    color: selectedCatalog === null ? "#fff" : "#000",
                    backgroundColor:
                      selectedCatalog === null
                        ? customTheme.primaryColor
                        : "#fff",
                    border:
                      selectedCatalog === null
                        ? "1px solid" + customTheme.primaryColor
                        : "1px solid lightgray",
                    "&:hover": {
                      backgroundColor: customTheme.primaryHoverColor,
                      color: "#fff",
                      border: "1px solid" + customTheme.primaryHoverColor,
                    },
                    marginBottom: "8px",
                  }}
                />
              </Grid>
              {Array.from(store.catalogStore.categories.values()).map(
                (category, index) => (
                  <Grid item key={category.id}>
                    <Chip
                      label={category.name}
                      onClick={() => handleSelectCatalog(category.name)}
                      sx={{
                        color:
                          selectedCatalog === category.name ? "#fff" : "#000",
                        backgroundColor:
                          selectedCatalog === category.name
                            ? customTheme.primaryColor
                            : "#fff",
                        border:
                          selectedCatalog === category.name
                            ? "1px solid" + customTheme.primaryColor
                            : "1px solid lightgray",
                        "&:hover": {
                          backgroundColor: customTheme.primaryHoverColor,
                          color: "#fff",
                          border: "1px solid" + customTheme.primaryHoverColor,
                        },
                        zIndex: 1,
                        marginBottom: "8px",
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>

            <MenuItemList menuItems={Array.from(filteredMenuItems.values())} />
          </Grid>
        </Grid>
      </ContentContainer>
      <MostLovedMenu
        mostLovedMenuItems={mostLovedMenuItems}
        handleAddToCart={handleAddToCart}
      />
    </>
  );
});
