import {
  Box,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  useFieldArray,
  useForm,
} from "react-hook-form";
import AppDropzone from "../../app/components/AppDropzone";
import AppSelectList from "../../app/components/AppSelectList";
import AppTextInput from "../../app/components/AppTextInput";
import useMenuItems from "../../app/hooks/useMenuItems";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./menuItemValidation";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../app/stores/store";
import { Category } from "../../app/models/category";
import { Delete } from "@mui/icons-material";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";
import ContentContainer from "../../app/common/container/ContentContainer";
import { useNavigate } from "react-router-dom";

export default function MenuItemForm() {
  const { catalogStore } = useStore();
  const { removeMenuItem, addMenuItem, updateMenuItem } = catalogStore;
  const { menuItem } = catalogStore;
  const [loading, setLoading] = useState(false);
  const [newMenuItem, setNewMenuItem] = useState(false);
  const navigate = useNavigate();

  console.log(menuItem);

  const {
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { tags, types, categories } = useMenuItems();
  const watchFile = watch("file", null);

  // const { handleSubmit } = useForm({
  //   defaultValues: {
  //     name: menuItem?.name || '',
  //     category: menuItem?.category || '',
  //     subCategory: menuItem?.subCategory || '',
  //     price: menuItem?.price || '',
  //     quantityInStock: menuItem?.quantityInStock || '',
  //     description: menuItem?.description || '',
  //     options: menuItem?.options || [],
  //   },
  // });

  const {
    fields: optionFields,
    append: optionAppend,
    remove: optionRemove,
  } = useFieldArray({
    control,
    name: "options",
  });

  useEffect(() => {
    if (menuItem === null) {
      setNewMenuItem(true);
    }
  }, [menuItem]);

  useEffect(() => {
    if (menuItem && !watchFile && !isDirty) reset(menuItem);
    return () => {
      if (watchFile) URL.revokeObjectURL(watchFile.preview);
    };
  }, [menuItem, reset, watchFile, isDirty]);

  const handleSubmitData = async (data: FieldValues) => {
    try {
      if (menuItem) {
        await updateMenuItem(data);
      } else {
        await addMenuItem(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mainCategories = Array.from(categories.values()).filter(
    (category) => !category.parentCategoryId
  );

  const [subCategories, setSubCategories] = useState<Category[]>([]);

  const selectedMainCategory = watch("category");

  const mainCategory = Array.from(categories.values()).find(
    (category) => category.name === selectedMainCategory
  );

  useEffect(() => {
    if (selectedMainCategory) {
      const filteredSubCategories = Array.from(categories.values()).filter(
        (category) => category.parentCategoryId === mainCategory?.id
      );
      setSubCategories(filteredSubCategories);
    } else {
      setSubCategories([]);
    }
  }, [selectedMainCategory, categories, mainCategory?.id]);

  useEffect(() => {
    const category = watch("category");

    if (selectedMainCategory && category !== menuItem?.category) {
      setValue("subCategory", null);
    }
  }, [watch, setValue, selectedMainCategory, menuItem?.category]);

  const handleRemoveOption = (index: number) => {
    optionRemove(index);
  };

  const handleAddOption = () => {
    optionAppend({
      name: "",
      required: false,
      choices: [{ name: "", price: 0 }],
    });
  };

  const removeChoice = (optionIndex: number, choiceIndex: number) => {
    const options = watch("options");
    options[optionIndex].choices = options[optionIndex].choices.filter(
      (choice: any, i: number) => i !== choiceIndex
    );
    setValue("options", [...options]);
  };

  const handleAddChoice = (optionIndex: number) => {
    const options = watch("options");
    const newChoice = { name: "", price: 0 };

    options[optionIndex].choices.push(newChoice);

    setValue("options", [...options]);
  };

  function handleDeleteMenuItem(id: number) {
    setLoading(true);
    agent.Admin.deleteMenuItem(id)
      .then(() => removeMenuItem(id))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
    toast.success("MenuItem deleted successfully");
    navigate("/inventory");
  }

  return (
    <ContentContainer>
      <Box sx={{ p: 4 }}>
        <Typography variant='h4' gutterBottom sx={{ mb: 4 }}>
          MenuItem Details
        </Typography>
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={2}>
              <AppTextInput
                control={control}
                name='displayOrder'
                label='Display Order'
              />
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <AppTextInput
                control={control}
                name='name'
                label='MenuItem name'
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <AppSelectList
              control={control}
              items={tags}
              name='tag'
              label='Tag'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppSelectList
              control={control}
              items={types}
              name='type'
              label='Type'
            />
          </Grid> */}
            <Grid item xs={12} sm={6}>
              <AppSelectList
                control={control}
                items={mainCategories.map((category) => category.name)}
                name='category'
                label='Main Category'
                defaultValue={menuItem?.category!}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppSelectList
                control={control}
                items={subCategories.map((category) => category.name)}
                name='subCategory'
                label='Sub Category'
                defaultValue={menuItem?.subCategory!}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                type='number'
                control={control}
                name='price'
                label='Price'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AppTextInput
                type='number'
                control={control}
                name='quantityInStock'
                label='Quantity in Stock'
              />
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                control={control}
                multiline={true}
                rows={4}
                name='description'
                label='Description'
              />
            </Grid>
            {optionFields.map((option: any, index: number) => (
              <Grid item xs={12} key={option.id}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    p: 2,
                    mb: 3,
                    position: "relative",
                  }}
                >
                  <Typography variant='h6'>Option {index + 1}</Typography>
                  <Grid
                    container
                    spacing={2}
                    alignItems='center'
                    sx={{ my: 2 }}
                  >
                    <Grid item xs={12} sm={9}>
                      <AppTextInput
                        control={control}
                        name={`options[${index}].name`}
                        label='Option Name'
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControlLabel
                        control={
                          <Controller
                            control={control}
                            name={`options[${index}].required`}
                            defaultValue={option.required}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        }
                        label='Required'
                      />
                    </Grid>
                  </Grid>
                  {option.choices.map((choice: any, choiceIndex: number) => (
                    <Grid
                      container
                      spacing={2}
                      key={choice.id}
                      alignItems='center'
                      sx={{ my: 2 }}
                    >
                      <Grid item xs={12} sm={7}>
                        <AppTextInput
                          control={control}
                          name={`options[${index}].choices[${choiceIndex}].name`}
                          label={`Choice ${choiceIndex + 1} Name`}
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <AppTextInput
                          control={control}
                          name={`options[${index}].choices[${choiceIndex}].price`}
                          label={`Choice ${choiceIndex + 1} Price`}
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Button
                          onClick={() => removeChoice(index, choiceIndex)}
                          variant='outlined'
                          color='warning'
                          size='small'
                        >
                          Remove Choice
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container item xs={12} sx={{ mt: 2 }}>
                    <Grid item xs={6} sm={6}>
                      <Button
                        onClick={() => handleAddChoice(index)}
                        variant='outlined'
                        color='primary'
                        size='small'
                      >
                        + Add Choice
                      </Button>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Button
                        onClick={() => handleRemoveOption(index)}
                        variant='outlined'
                        color='error'
                        size='small'
                      >
                        Remove Option
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
            <Grid item xs={6}>
              <Button
                onClick={() => handleAddOption()}
                variant='outlined'
                color='primary'
                size='small'
              >
                + Add Option
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box
                display='flex'
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent='space-between'
                alignItems='center'
              >
                <AppDropzone control={control} name='file' />
                {watchFile ? (
                  <img
                    src={watchFile.preview}
                    alt='preview'
                    style={{
                      padding: "1rem",
                      maxHeight: "15rem",
                      width: "auto",
                    }}
                  />
                ) : (
                  <img
                    src={menuItem?.pictureUrl}
                    alt={menuItem?.name}
                    style={{
                      padding: "1rem",
                      maxHeight: "15rem",
                      width: "auto",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
            <div>
              <Button
                onClick={() => navigate(-1)}
                variant='contained'
                color='inherit'
                sx={{ marginRight: 5 }}
              >
                Cancel
              </Button>
              {!newMenuItem && (
                <LoadingButton
                  loading={loading}
                  onClick={() => handleDeleteMenuItem(menuItem!.id)}
                  startIcon={<Delete />}
                  variant='contained'
                  color='error'
                >
                  Remove
                </LoadingButton>
              )}
            </div>

            <LoadingButton
              loading={isSubmitting}
              type='submit'
              variant='contained'
              color='success'
            >
              Submit
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </ContentContainer>
  );
}
