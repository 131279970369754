import { useFormContext } from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInput";
import { Typography, Grid, TextField } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { StripeInput } from "./StripeInput";
import { StripeElementType } from "@stripe/stripe-js";

interface Props {
  cardState: { elementError: { [key in StripeElementType]?: string } };
  onCardInputChange: (event: any) => void;
}

const handleReady = (element: any, onCardInputChange: (event: any) => void) => {
  element.on("change", (event: any) => onCardInputChange(event));
};

export default function PaymentForm({ cardState, onCardInputChange }: Props) {
  const { control } = useFormContext();

  const cardNumberReady = (element: any) =>
    handleReady(element, onCardInputChange);
  const cardExpiryReady = (element: any) =>
    handleReady(element, onCardInputChange);
  const cardCvcReady = (element: any) =>
    handleReady(element, onCardInputChange);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <AppTextInput
            name='nameOnCard'
            label='Name on card'
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={onCardInputChange}
            error={!!cardState.elementError.cardNumber}
            helperText={cardState.elementError.cardNumber}
            id='cardNumber'
            label='Card number'
            fullWidth
            autoComplete='cc-number'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
                onReady: cardNumberReady,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={onCardInputChange}
            error={!!cardState.elementError.cardExpiry}
            helperText={cardState.elementError.cardExpiry}
            id='expDate'
            label='Expiry date'
            fullWidth
            autoComplete='cc-exp'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
                onReady: cardExpiryReady,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            onChange={onCardInputChange}
            error={!!cardState.elementError.cardCvc}
            helperText={cardState.elementError.cardCvc}
            id='cvv'
            label='CVV'
            fullWidth
            autoComplete='cc-csc'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
                onReady: cardCvcReady,
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
