import * as yup from "yup";

export const validationSchema = yup.object({
  name: yup.string().required(),
  // tag: yup.string().required(),
  // type: yup.string().required(),
  price: yup.number().required().moreThan(1),
  quantityInStock: yup.number().required().min(0),
  description: yup.string().required(),
  file: yup.mixed().when("pictureUrl", {
    is: (value: string) => !value,
    then: yup.mixed().required("Please provide an image"),
  }),
  options: yup.array().of(
    yup.object({
      price: yup.number(),
      id: yup.number(),
      name: yup.string(),
      required: yup.boolean(),
      optionTotalprice: yup.number(),
      choices: yup.array().of(
        yup.object({
          id: yup.number(),
          choiceId: yup.number(),
          name: yup.string(),
          price: yup.number(),
        })
      ),
    })
  ),
});
