import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Grid,
  Avatar,
  Typography,
  IconButton,
  Divider,
  TextField,
  FormHelperText,
} from "@mui/material";
import {
  CheckCircleOutlineOutlined,
  EditNoteOutlined,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { store } from "../../app/stores/store";
import ContentContainer from "../../app/common/container/ContentContainer";
import { LoadingButton } from "@mui/lab";
import DeleteAccount from "./DeleteAccount";
import { UserFormUpdateValues } from "../../app/models/user";
import PasswordEditForm from "./PasswordEditForm";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

const AccountPage = observer(() => {
  const { modalStore } = store;
  const { user, fetchCurrentUser, signOut, updateProfile } = store.userStore;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [editMode, setEditMode] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [displayNameError, setDisplayNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);
  const [usernameChanged, setUsernameChanged] = useState(false);
  const { theme: customTheme } = useCustomTheme();

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  useEffect(() => {
    fetchCurrentUser().then(() => {
      // Initialize the display name and phone number states
      setDisplayName(user?.userName || `User ${user?.id}`);
      setPhoneNumber(user?.phoneNumber || "");
    });
  }, [fetchCurrentUser]);

  useEffect(() => {
    if (displayNameError || phoneNumberError) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [displayNameError, phoneNumberError]);

  useEffect(() => {
    validateDisplayName(displayName);
    validatePhoneNumber(phoneNumber);
  }, [displayName, phoneNumber]);

  const handleDeleteAccount = () => {
    modalStore.openModal(<DeleteAccount />);
  };

  const getDisplayName = () => {
    if (user?.userName) {
      return user.userName;
    }
    return "User " + user?.id;
  };

  const handleUpdateProfile = async () => {
    const isDisplayNameValid = validateDisplayName(displayName);
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
    setDisableUpdateButton(!(isDisplayNameValid && isPhoneNumberValid));

    if (!isDisplayNameValid || !isPhoneNumberValid) {
      return;
    }

    try {
      await updateProfile({
        userName: displayName,
        phoneNumber,
      } as UserFormUpdateValues);
      setEditMode(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const validateDisplayName = (name: string) => {
    if (!name || name.trim().length < 1 || name.trim().length > 16) {
      setDisplayNameError(
        "Display name is required and should be between 1 and 16 characters"
      );
      return false;
    } else {
      setDisplayNameError("");
      return true;
    }
  };

  const validatePhoneNumber = (number: string) => {
    const phoneNumberPattern = /^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/;
    if (!number || !phoneNumberPattern.test(number)) {
      setPhoneNumberError("Invalid phone number");
      return false;
    } else {
      setPhoneNumberError("");
      return true;
    }
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  return (
    <ContentContainer>
      <Grid
        container
        spacing={2}
        alignItems='center'
        sx={{
          width: isMobile ? "100%" : "20rem",
          mx: "auto",
          paddingRight: 2,
        }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Avatar
              src={user?.image}
              sx={{
                color: customTheme.primaryColor,
                width: 50,
                height: 50,
                backgroundColor: "#fff",
                border: "2px solid",
                borderColor: customTheme.primarColor,
              }}
            />
          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <Typography variant='h6' align='center'>
              {getDisplayName()}
            </Typography>

            <IconButton
              onClick={() => {
                setEditMode(!editMode);
                if (editMode && isFormValid) {
                  handleUpdateProfile();
                  setDisableUpdateButton(true);
                }
              }}
              disabled={editMode && !isFormValid}
            >
              {editMode ? (
                <CheckCircleOutlineOutlined
                  sx={{ color: isFormValid ? "green" : "red" }}
                />
              ) : (
                <EditNoteOutlined />
              )}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <Typography variant='body1' align='center'>
                {user?.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent='center'>
            {editMode ? (
              <Grid item xs={12}>
                <TextField
                  error={!!displayNameError}
                  value={displayName}
                  fullWidth
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                    setUsernameChanged(true);
                  }}
                  disabled={!editMode}
                  required
                  placeholder='Enter your username'
                  sx={{
                    paddingInline: { xs: 5, sm: 4 },
                    "& .MuiInputBase-input": {
                      borderRadius: "30px",
                      backgroundColor: "#e3e3e3",
                      height: "1px",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#A0AAB4",
                    },
                    "& .MuiInputLabel-shrink": {
                      color: "#A0AAB4",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                      "& fieldset": {
                        borderColor: "#e3e3e3",
                      },
                      "&:hover fieldset": {
                        borderColor: "#e3e3e3",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#e3e3e3",
                      },
                    },
                  }}
                />
                {displayNameError && (
                  <FormHelperText error>{displayNameError}</FormHelperText>
                )}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {editMode ? (
                <>
                  <TextField
                    error={!!phoneNumberError}
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setPhoneNumberChanged(true);
                    }}
                    disabled={!editMode}
                    required
                    fullWidth
                    placeholder='Enter your phone number'
                    sx={{
                      paddingInline: { xs: 5, sm: 4 },
                      "& .MuiInputBase-input": {
                        borderRadius: "30px",
                        backgroundColor: "#e3e3e3",
                        height: "1px",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#A0AAB4",
                      },
                      "& .MuiInputLabel-shrink": {
                        color: "#A0AAB4",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                        "& fieldset": {
                          borderColor: "#e3e3e3",
                        },
                        "&:hover fieldset": {
                          borderColor: "#e3e3e3",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#e3e3e3",
                        },
                      },
                    }}
                  />
                  {phoneNumberError && (
                    <FormHelperText error>{phoneNumberError}</FormHelperText>
                  )}
                </>
              ) : (
                <Typography variant='body1' align='center'>
                  {user?.phoneNumber
                    ? formatPhoneNumber(user?.phoneNumber)
                    : "Please enter your phone number"}
                </Typography>
              )}
            </Grid>
            {editMode ? <></> : null}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ mb: 2, borderTop: "2px solid #e3e3e3" }} />
        </Grid>
        {user?.provider === "Local" && (
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                <LoadingButton
                  loading={false} // Set loading state to false
                  onClick={() =>
                    modalStore.openModal(
                      <PasswordEditForm setEditMode={() => true} />
                    )
                  }
                  variant='contained'
                  fullWidth
                  sx={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "center",
                    my: 2,
                    margin: "auto",
                    bgcolor: customTheme.primaryColor,
                    "&:hover": { bgcolor: customTheme.primaryHoverColor },
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    boxShadow: "none",
                  }}
                >
                  Change Password
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <LoadingButton
                loading={false} // Set loading state to false
                onClick={signOut}
                variant='contained'
                fullWidth
                sx={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "center",
                  my: 1,
                  margin: "auto",
                  bgcolor: customTheme.primaryColor,
                  "&:hover": { bgcolor: customTheme.primaryHoverColor },
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                Log Out
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <LoadingButton
                loading={false} // Set loading state to false
                onClick={handleDeleteAccount}
                variant='contained'
                fullWidth
                sx={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "center",
                  my: 1,
                  margin: "auto",
                  bgcolor: customTheme.primaryColor,
                  "&:hover": { bgcolor: customTheme.primaryHoverColor },
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                Delete Account
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentContainer>
  );
});

export default AccountPage;
