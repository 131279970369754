import {
  Box,
  Typography,
  Grid,
  CardContent,
  Card,
  CardHeader,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { currencyFormat } from "../../app/util/util";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { BasketItem } from "../../app/models/basket";
import { OrderItem } from "../../app/models/order";
import ContentContainer from "../../app/common/container/ContentContainer";

export default observer(function OrderDetailed() {
  const { id } = useParams<{ id: string }>();

  const { basketStore } = useStore();
  const { subtotal, getTotal, selectedOrder: order, loadOrder } = basketStore;
  const [Gratuity, setGratuity] = useState(0);
  const theme = useTheme();

  if (order === null) loadOrder(parseInt(id!));
  // console.log(order);

  // useEffect(() => {
  //     // (order && order.total && order.total >= 100000) || order.total === 0
  //     //   ? setGratuity(0)
  //     //   : setGratuity(500);
  //   }
  // }, [id, loadOrder, order]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const items = order && (order.orderItems as OrderItem[]);

  console.log(items);

  if (!order) {
    return <LoadingComponent />;
  }
  return (
    <ContentContainer>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <Card sx={{ marginBottom: "1rem" }} variant='outlined'>
            <CardHeader
              titleTypographyProps={{ variant: "body2" }}
              title={`Order# ${order.id} - ${order.orderStatus}`}
              subheader={`Order date: ${formatDate(order.orderDate)}`}
            />
            <CardContent>
              <Typography variant='caption'>
                {items && items[0]?.name} and {items && items.length - 1} more
                item(s)
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ marginBottom: "2rem" }}>
            <Card
              variant='outlined'
              sx={{
                mb: 2,
              }}
            >
              {items &&
                items.map((item) => (
                  <CardContent key={item.menuItemId}>
                    <Grid container justifyContent='space-between'>
                      <Grid item xs={7} sm={7} md={7}>
                        <Typography variant='caption'>
                          {item.name} : {currencyFormat(item.price)}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} textAlign='right'>
                        <Typography variant='caption'>
                          Subtotal : {currencyFormat(order.subtotal)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box>
                      {/* <Typography variant='caption' gutterBottom>
                      Price: {currencyFormat(item.price)}
                    </Typography> */}
                      {item.selectedOptionChoices &&
                        item.selectedOptionChoices.map((selectedOption) => (
                          <Box key={selectedOption.optionChoice.id}>
                            <Typography variant='caption' gutterBottom>
                              {selectedOption.optionChoice.name}:{" "}
                              {currencyFormat(
                                selectedOption.optionChoice.price
                              )}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </CardContent>
                ))}
            </Card>
            <Card
              variant='outlined'
              sx={{
                mb: 2,
              }}
            >
              <CardContent>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{ mb: 1 }}
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography variant='caption'>Subtotal</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} textAlign='right'>
                    <Typography variant='caption'>
                      {currencyFormat(order.subtotal)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{ mb: 1 }}
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography variant='caption'>Delivery fee*</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} textAlign='right'>
                    <Typography variant='caption'>
                      {currencyFormat(order.Gratuity)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography variant='caption'>Total</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} textAlign='right'>
                    <Typography variant='caption'>
                      {currencyFormat(order.total)}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 1 }}>
                  <Typography variant='caption' fontStyle='italic'>
                    *Orders over $100 qualify for free delivery
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </ContentContainer>
  );
});
