import {
  Box,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Container,
  TableBody,
  Input,
} from "@mui/material";
import useMenuItems from "../../app/hooks/useMenuItems";
import { MenuItem } from "../../app/models/menuItem";
import { currencyFormat } from "../../app/util/util";
import MenuItemForm from "./MenuItemForm";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { ChangeEvent } from "react";

export default observer(function Inventory() {
  const { catalogStore } = useStore();
  const { menuItem, menuItems, setMenuItem, updateMenuItemDisplayOrder } =
    catalogStore;
  const navigate = useNavigate();

  function handleUpdateMenuItem(menuItem: MenuItem) {
    setMenuItem(menuItem);
    navigate(`/inventory/${menuItem!.id}`);
  }

  function handleCreateMenuItem() {
    setMenuItem(null);
    navigate(`/inventory/new`);
  }

  function customSort(a: any, b: any) {
    if (a.category === b.category) {
      if (a.subCategory === b.subCategory) {
        return a.name.localeCompare(b.name);
      }
      return a.subCategory.localeCompare(b.subCategory);
    }
    return a.category.localeCompare(b.category);
  }

  // let sortedMenuItems = Array.from(menuItems.values()).sort(customSort);
  let sortedMenuItems = Array.from(menuItems.values()).sort(
    (a, b) => a.displayOrder - b.displayOrder
  );

  const handleDisplayOrderChange = (e: any, menuItemId: number) => {
    const newOrder = parseInt(e.target.value, 10);
    if (!isNaN(newOrder)) {
      updateMenuItemDisplayOrder(menuItemId, newOrder);
    }
  };

  return (
    <Container sx={{ my: 5 }}>
      <Box display='flex' justifyContent='end'>
        <Button
          onClick={() => handleCreateMenuItem()}
          sx={{ m: 2 }}
          size='large'
          variant='contained'
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell></TableCell>
              <TableCell align='center'>MenuItem</TableCell>
              <TableCell align='center'>Price</TableCell>
              <TableCell align='center'>Category</TableCell>
              <TableCell align='center'>Sub-Category</TableCell>
              <TableCell align='center'>Quantity</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(sortedMenuItems.values()).map((menuItem, index) => (
              <TableRow
                key={menuItem.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleUpdateMenuItem(menuItem)}
              >
                <TableCell component='th' scope='row'>
                  <Input
                    id={`displayOrder-${menuItem.id}`}
                    value={menuItem.displayOrder}
                    onChange={(e) => handleDisplayOrderChange(e, menuItem.id)}
                    type='number'
                    sx={{ width: "60px" }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
                <TableCell align='left'>
                  <Box display='flex' alignItems='center'>
                    <img
                      src={
                        menuItem.pictureUrl ||
                        `${process.env.PUBLIC_URL}/assets/placeholder.png`
                      }
                      alt={menuItem.name}
                      style={{ width: "50px", height: "50px" }}
                    />
                  </Box>
                </TableCell>
                <TableCell align='center'>{menuItem.name}</TableCell>
                <TableCell align='right'>
                  {currencyFormat(menuItem.price)}
                </TableCell>
                <TableCell align='center'>{menuItem.category}</TableCell>
                <TableCell align='center'>{menuItem.subCategory}</TableCell>
                <TableCell align='center'>{menuItem.quantityInStock}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {metaData && (
        <Box sx={{ pt: 2 }}>
          <AppPagination
            metaData={metaData}
            onPageChange={(page: number) => setPageNumber(page)}
          />
        </Box>
      )} */}
    </Container>
  );
});
