import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import ModalStore from "./modalStore";
import BasketStore from "./basketStore";
import CatalogStore from "./catalogStore";
import CategoryStore from "./categoryStore";
import AdminStore from "./adminStore";
import OrderStore from "./orderStore";

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  catalogStore: CatalogStore;
  basketStore: BasketStore;
  categoryStore: CategoryStore;
  adminStore: AdminStore;
  orderStore: OrderStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  catalogStore: new CatalogStore(),
  basketStore: new BasketStore(),
  categoryStore: new CategoryStore(),
  adminStore: new AdminStore(),
  orderStore: new OrderStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
