import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { DeleteAccountFormValues } from "../../app/models/user";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import AccountPage from "./AccountPage";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

const DeleteAccount = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    watch,
  } = useForm<DeleteAccountFormValues>({
    mode: "onTouched",
  });

  const { userStore } = useStore();
  const { deleteUserAccount, user, signOut } = userStore;
  const [isDeleted, setIsDeleted] = useState(false);
  const [emailMatchError, setEmailMatchError] = useState(false);
  const enteredEmail = watch("email");
  const { modalStore } = useStore();
  const { theme: customTheme } = useCustomTheme();

  useEffect(() => {
    setEmailMatchError(false);
  }, [enteredEmail]);

  const submitForm = async (data: FieldValues) => {
    if (enteredEmail === user?.email) {
      try {
        const formData: DeleteAccountFormValues = {
          email: data.email,
          password:
            user?.provider === "Local" ? data.password : "defaultPassword",
        };
        await deleteUserAccount(formData);
        setIsDeleted(true);
        signOut();
      } catch (error) {
        console.log(error);
      }
    } else {
      setEmailMatchError(true);
    }
  };

  if (isDeleted) {
    return (
      <Container
        maxWidth='sm'
        sx={{
          p: 2,
          width: "20rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component='h1' variant='h5'>
          Account Deleted
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button
            variant='outlined'
            component={Link}
            to='/register'
            sx={{
              borderRadius: "20px",
              textTransform: "none",
              color: customTheme.primaryColor,
              backgroundColor: "#fff", // Set background color to customTheme.primaryColor (orange)
              borderColor: customTheme.primaryColor, // Set border color to customTheme.primaryColor (orange)
              "&:hover": {
                backgroundColor: "#fffbf7", // Change hover background color to customTheme.primaryColor (orange)
                borderColor: customTheme.primaryColor, // Change hover border color to customTheme.primaryColor (orange)
                color: customTheme.primaryColor,
              },
              fontSize: { xs: "0.8rem", sm: "0.8rem", md: "1rem" }, // Adjust font size to make buttons smaller
              width: "100%",
              mt: 2,
            }}
          >
            Go back to Menu
          </Button>
          <Button
            variant='outlined'
            component={Link}
            to='/'
            sx={{
              borderRadius: "20px",
              textTransform: "none",
              color: "white",
              backgroundColor: customTheme.primaryColor, // Set background color to customTheme.primaryColor (orange)
              borderColor: customTheme.primaryColor, // Set border color to customTheme.primaryColor (orange)
              "&:hover": {
                backgroundColor: customTheme.primaryHoverColor, // Change hover background color to customTheme.primaryColor (orange)
                borderColor: customTheme.primaryColor, // Change hover border color to customTheme.primaryColor (orange)
                color: "white",
              },
              fontSize: { sm: "0.8rem", md: "1rem" }, // Adjust font size to make buttons smaller
              width: "100%",
              mt: 2,
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Grid container justifyContent='flex-start'>
        <Grid item>
          <IconButton
            onClick={() => {
              modalStore.openModal(<AccountPage />);
            }}
            edge='start'
            sx={{
              paddingLeft: 2,
              textTransform: "none",
              color: customTheme.primaryColor,
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: customTheme.primaryHoverColor,
              },
            }}
          >
            <ArrowBackIos sx={{ fontSize: { sm: "1rem", md: "2rem" } }} />
            <Typography variant='subtitle1'>Back to Account</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Container
        maxWidth='sm'
        sx={{
          p: 2,
          width: "20rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component='h1' variant='h5'>
          Delete Account
        </Typography>
        <Box
          component='form'
          onSubmit={handleSubmit(submitForm)}
          noValidate
          sx={{ mt: 2 }}
        >
          <Typography variant='body2' sx={{ mt: 2, mb: 4, color: "red" }}>
            Warning: Deleting your account is irreversible. Please confirm your
            email to proceed.
          </Typography>
          <TextField
            required
            fullWidth
            autoFocus
            placeholder='Confirm your email'
            {...register("email", {
              required: "Email confirmation is required",
            })}
            error={!!errors.email || emailMatchError}
            helperText={
              emailMatchError
                ? "Email does not match. Please enter the correct email."
                : (errors?.email?.message as string)
            }
            sx={{
              mb: 4,
              "& .MuiInputBase-input": {
                borderRadius: "30px",
                backgroundColor: "#e3e3e3",
                height: "1px",
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                "& fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3",
                },
              },
            }}
          />

          <LoadingButton
            disabled={!isValid || emailMatchError}
            loading={isSubmitting}
            type='submit'
            variant='contained'
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 10,
              margin: "auto",
              width: "70%",
              bgcolor: customTheme.primaryColor,
              "&:hover": { bgcolor: customTheme.primaryHoverColor },
              borderRadius: "30px",
              textTransform: "capitalize",
              boxShadow: "none",
            }}
          >
            Delete Account
          </LoadingButton>
        </Box>
      </Container>
    </>
  );
});

export default DeleteAccount;
