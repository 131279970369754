import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "../../../app/models/menuItem";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import MenuItemDetails from "./MenuItemDetails";

interface Props {
  menuItem: MenuItem;
  style?: React.CSSProperties;
}

export default observer(function MenuItemCard({ menuItem, style }: Props) {
  const { catalogStore, modalStore } = useStore();
  const { setMenuItem } = catalogStore;
  const navigate = useNavigate();

  const handleContentClick = () => {
    setMenuItem(menuItem);
    modalStore.openModal(<MenuItemDetails />);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const maxDescriptionLength = isMobile ? 30 : 60;
  const truncatedDescription =
    menuItem.description.length > maxDescriptionLength
      ? menuItem.description.substring(0, maxDescriptionLength) + "..."
      : menuItem.description;

  return (
    <Card
      sx={{
        display: "flex",
        flexBasis: "100%",
        width: "100%",
        cursor: "pointer",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        ...style,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          maxWidth: "calc(100% - 100px)",
        }}
      >
        <CardContent
          sx={{
            flex: "1 0 auto",
            width: "100%",
            padding: "16px",
            backgroundColor: "#fff",
            zIndex: 2,
          }}
          onClick={handleContentClick}
        >
          <Typography variant='subtitle1' color='text.primary' sx={{}}>
            {isMobile ? menuItem.name.substring(0, 20) + "..." : menuItem.name}
          </Typography>
          <Typography variant='body2' color='text.secondary' sx={{}}>
            {truncatedDescription}
          </Typography>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ position: "relative", top: "1.5rem" }}
          >
            ${menuItem.price}
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component='img'
        sx={{
          width: "8rem",
          height: "8rem",
          bgcolor: "#E0E0E0",
          objectFit: "cover",
          zIndex: 2,
        }}
        image={
          menuItem.pictureUrl ||
          `${process.env.PUBLIC_URL}/assets/placeholder.png`
        }
        alt={menuItem.name}
        title={menuItem.name}
        onClick={handleContentClick}
      />
    </Card>
  );
});
