import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../app/api/agent";
import { UserForAdminControls } from "../../app/models/user";
import { toast } from "react-toastify";
import { Order } from "../models/order";

export default class AdminStore {
  user: UserForAdminControls | null = null;
  users: UserForAdminControls[] = [];
  orders: Order[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadOrders = async () => {
    try {
      const orders = await agent.Admin.orderList();
      runInAction(() => {
        this.orders = orders;
      });
    } catch (error) {
      console.log(error);
    }
  };

  //get all users, get a user, update a user, delete a user, create a user
  //get all users
  loadUsers = async () => {
    try {
      const users = await agent.User.list();
      runInAction(() => {
        this.users = users;
      });
    } catch (error) {
      console.log(error);
    }
  };

  //get a user
  loadUser = async (id: String) => {
    try {
      const user = await agent.User.details(id);
      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      console.log(error);
    }
  };

  //update a user
  updateUser = async (user: UserForAdminControls) => {
    try {
      await agent.User.update(user);
      runInAction(() => {
        this.user = user;
      });
      toast.success("User updated successfully");
    } catch (error) {
      console.log(error);
    } finally {
      this.loadUsers();
    }
  };

  deleteUser = async (id: String) => {
    try {
      await agent.User.delete(id);
      runInAction(() => {
        this.user = null;
      });
      toast.success("User deleted successfully");
    } catch (error) {
      console.log(error);
    } finally {
      this.loadUsers();
    }
  };

  //create a user
  createUser = async (user: UserForAdminControls) => {
    try {
      await agent.User.create(user);
      runInAction(() => {
        this.user = user;
      });
      toast.success("User created successfully");
    } catch (error) {
      console.log(error);
    }
  };
}
