import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "../../app/models/menuItem";
import { currencyFormat } from "../../app/util/util";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import MenuItemDetail from "./MenuItemDetails";

interface Props {
  menuItem: MenuItem;
}

export default observer(function MenuItemCard({ menuItem }: Props) {
  const { catalogStore } = useStore();
  const { setMenuItem } = catalogStore;
  const navigate = useNavigate();

  const handleContentClick = () => {
    setMenuItem(menuItem);
    navigate(`/catalog/${menuItem.id}`);
  };

  return (
    <Card
      sx={{
        display: "flex",
        marginY: 1,
        flexBasis: "100%",
        width: "100%",
      }}
    >
      <CardMedia
        component='img'
        sx={{
          width: "100px",
          height: "100px",
          margin: "1rem 1rem",
          bgcolor: "primary.light",
          borderRadius: "4px",
        }}
        image={
          menuItem.pictureUrl ||
          `${process.env.PUBLIC_URL}/assets/placeholder.png`
        }
        alt={menuItem.name}
        title={menuItem.name}
        onClick={handleContentClick}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          maxWidth: "calc(100% - 160px)",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }} onClick={handleContentClick}>
          <Typography
            variant='h6'
            color='primary.main'
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            {menuItem.name}
          </Typography>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ marginBottom: 1 }}
          >
            {menuItem.category}
          </Typography>
          <Typography gutterBottom color='secondary' variant='h5'>
            {currencyFormat(menuItem.price)}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
});
