import { makeAutoObservable, runInAction } from "mobx";
import * as signalR from "@microsoft/signalr";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import agent from "../api/agent";
import { Order } from "../models/order";

export default class OrderStore {
  orders: Order[] = [];
  selectedOrder: Order | null = null;
  ws: WebSocket | null = null;
  connection: HubConnection | null = null;

  constructor() {
    makeAutoObservable(this);
    this.initSignalRConnection();
    this.loadOrders();
  }

  initSignalRConnection() {
    const signalRUrl = "http://localhost:5000/orderhub";
    // process.env.ORDER_HUB_URL ||

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrl)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection
      .start()
      .then(() => {
        console.log("Connected to SignalR hub successfully!");
        this.registerSignalREvents();
      })
      .catch((err) => console.error("SignalR Connection Error: ", err));
  }

  registerSignalREvents() {
    if (this.connection) {
      this.connection.on("ReceiveOrderUpdate", (data) => {
        const order = JSON.parse(data);
        runInAction(() => {
          this.handleOrderUpdate(order);
        });
      });

      this.connection.on("ReceiveNewOrder", (data) => {
        const order = JSON.parse(data);
        runInAction(() => {
          this.addNewOrder(order);
        });
      });
    } else {
      console.error("SignalR connection is not established.");
    }
  }

  addNewOrder = (order: any) => {
    runInAction(() => {
      this.orders.push(order);
    });
  };

  handleOrderUpdate = (updatedOrder: any) => {
    runInAction(() => {
      const index = this.orders.findIndex(
        (order) => order.id === updatedOrder.id
      );
      if (index !== -1) {
        this.orders[index] = { ...this.orders[index], ...updatedOrder };
      } else {
        // Optionally add the order if it doesn't exist already
        this.orders.push(updatedOrder);
      }
    });
  };

  loadOrders = async () => {
    try {
      const orders = await agent.Orders.list();
      runInAction(() => {
        this.orders = orders;
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadOrder = async (id: number, order?: Order) => {
    this.selectedOrder = order ?? null;
    if (!this.selectedOrder) {
      try {
        const resultOrder = await agent.Orders.fetch(id);
        runInAction(() => {
          this.selectedOrder = resultOrder;
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  updateOrder = async (id: number, order: any) => {
    try {
      const response = await agent.Orders.update(id, order);
      runInAction(() => {
        const index = this.orders.findIndex((o) => o.id === id);
        if (index !== -1) {
          this.orders[index] = { ...this.orders[index], ...response.data };
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
}
