import { Grid, Typography, Box } from "@mui/material";
import ContentContainer from "../../app/common/container/ContentContainer";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { TimerOutlined } from "@mui/icons-material";

const LocationInfo = () => {
  return (
    <ContentContainer>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sx={{ margin: "2rem 0" }}>
          <Typography
            variant='h4'
            align='center'
            gutterBottom
            fontWeight='bold'
          >
            Location
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "100%",
              height: 300,
              overflow: "hidden",
            }}
          >
            <iframe
              title='Google Map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2616.3871664489966!2d-122.80992092299805!3d49.02224297135482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485c3b6cfa1245f%3A0x7bdaba5c3f9fd792!2sOVERFLO%20Dessert%20Cafe!5e0!3m2!1sen!2sca!4v1721061478265!5m2!1sen!2sca'
              width='100%'
              height={300}
              frameBorder='0'
              style={{ border: 0 }}
              allowFullScreen
            ></iframe>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              marginBottom: 2,
            }}
          >
            <RoomOutlinedIcon
              sx={{
                fontSize: { xs: 20, sm: 40 },
                marginInline: {
                  xs: 1,
                  sm: 5,
                },
              }}
            />
            <Typography
              variant='subtitle1'
              sx={{ fontSize: { xs: 10, sm: 18 } }}
            >
              14973 Marine Dr, White Rock, BC V4B 1C3
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              marginBottom: 2,
            }}
          >
            <TimerOutlined
              sx={{
                fontSize: { xs: 20, sm: 40 },
                marginInline: {
                  xs: 1,
                  sm: 5,
                },
              }}
            />
            <Box>
              <Typography
                variant='subtitle1'
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 1 }}
              >
                Mon - Thu
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 2 }}
              >
                1:00 p.m. - 11:00 p.m.
              </Typography>
              <Typography
                variant='subtitle1'
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 1 }}
              >
                Fri - Sun :
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 10, sm: 18 }, marginBottom: 2 }}
              >
                12:00 p.m. - 12:00 a.m.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <PhoneOutlinedIcon
              sx={{
                fontSize: { xs: 20, sm: 40 },
                marginInline: {
                  xs: 1,
                  sm: 5,
                },
              }}
            />
            <Typography
              variant='subtitle1'
              sx={{ fontSize: { xs: 10, sm: 18 } }}
            >
              (778) 987-1250
              <br />
              info@agorafield.com
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default LocationInfo;
