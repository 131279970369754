import { Form, Formik, ErrorMessage, Field } from "formik";
import { observer } from "mobx-react-lite";
import {
  Button,
  TextField,
  Box,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import ContentContainer from "../../app/common/container/ContentContainer";
import AccountPage from "./AccountPage";
import { Link } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

interface Props {
  // profile: any;
  setEditMode: (editMode: boolean) => void;
}

export default observer(function PasswordEditForm({
  // profile,
  setEditMode,
}: Props) {
  const {
    userStore: { updatePassword, isUpdated },
    modalStore,
  } = useStore();

  // if (!profile) {
  //   loadProfile();
  // }
  const { theme: customTheme } = useCustomTheme();

  return (
    // profile && (
    <ContentContainer>
      <Grid item>
        <IconButton
          onClick={() => {
            modalStore.openModal(<AccountPage />);
          }}
          edge='start'
          sx={{
            paddingLeft: 2,
            textTransform: "none",
            color: customTheme.primaryColor,
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
              color: customTheme.primaryHoverColor,
            },
          }}
        >
          <ArrowBackIos sx={{ fontSize: { sm: "1rem", md: "2rem" } }} />
          <Typography variant='subtitle1'>Back to Account</Typography>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' align='center' gutterBottom>
          Password Update
        </Typography>
      </Grid>
      <Box sx={{ paddingInline: { xs: "1rem", sm: "2rem", md: "7rem" } }}>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={(values: any) => {
            updatePassword(values)
              .then(() => {
                setEditMode(false);
              })
              .then(() => {
                if (isUpdated) modalStore.openModal(<AccountPage />);
              });
          }}
          validationSchema={Yup.object({
            currentPassword: Yup.string().required(
              "Current password is required"
            ),
            newPassword: Yup.string()
              .required("New password is required")
              .min(8, "New password must be at least 8 characters"),
            confirmPassword: Yup.string()
              .required("Confirm password is required")
              .oneOf([Yup.ref("newPassword")], "Passwords must match"),
          })}
        >
          {({ isSubmitting, isValid, dirty, errors, touched }) => (
            <Form
              className='ui form'
              style={{
                border: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Field
                  as={TextField}
                  fullWidth
                  margin='normal'
                  label='Username'
                  name='username'
                  error={Boolean(errors.username && touched.username)}
                  helperText={<ErrorMessage name='username' />}
                /> */}
              <Field
                as={TextField}
                fullWidth
                margin='dense'
                label='Current Password'
                name='currentPassword'
                type='password'
                error={Boolean(
                  errors.currentPassword && touched.currentPassword
                )}
                helperText={<ErrorMessage name='currentPassword' />}
                sx={{ display: "block" }}
              />
              <Field
                as={TextField}
                fullWidth
                margin='dense'
                label='New Password'
                name='newPassword'
                type='password'
                error={Boolean(errors.newPassword && touched.newPassword)}
                helperText={<ErrorMessage name='newPassword' />}
                sx={{ display: "block" }}
              />
              <Field
                as={TextField}
                fullWidth
                margin='dense'
                label='Confirm Password'
                name='confirmPassword'
                type='password'
                error={Boolean(
                  errors.confirmPassword && touched.confirmPassword
                )}
                helperText={<ErrorMessage name='confirmPassword' />}
                sx={{ display: "block", marginBottom: "2rem" }}
              />
              {/* <Field
                  as={TextField}
                  fullWidth
                  margin="normal"
                  label="Phone Number"
                  name="phoneNumber"
                  error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                  helperText={<ErrorMessage name="phoneNumber" />}
                /> */}
              <Button
                variant='contained'
                type='submit'
                disabled={!isValid}
                fullWidth
                onClick={() => {
                  if (isSubmitting) return;
                }}
                sx={{
                  width: "70%",
                  display: "flex",
                  justifyContent: "center",
                  my: 1,
                  margin: "auto",
                  bgcolor: customTheme.primaryColor,
                  "&:hover": { bgcolor: customTheme.primaryHoverColor },
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  boxShadow: "none",
                }}
              >
                Update password
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </ContentContainer>
    // )
  );
});
