import { Grid, Typography, Box } from "@mui/material";
import { currencyFormat } from "../../app/util/util";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

interface Props {
  subtotal?: number;
}

export default observer(function BasketSummary() {
  const { basketStore } = useStore();
  const { subtotal, gst, pst, total, gratuity } = basketStore.getTotalDetails();

  const discountRate = parseFloat(process.env.REACT_APP_DISCOUNT_RATE ?? "0");
  const discountAmount = subtotal * discountRate;

  return (
    <Grid
      container
      sx={{
        md: { paddingLeft: 2, paddingRight: 4 },
        p: { xs: 0, sm: 1 },
        mt: { xs: 1 },
      }}
    >
      <Grid item xs={6} md={2}>
        <Typography
          variant='h6'
          sx={{
            fontSize: { xs: "1rem", sm: "h6.fontSize" },
          }}
        >
          Subtotal
        </Typography>
      </Grid>
      <Grid item xs={0} md={8} sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "100%",
            borderTop: "2px solid #e3e3e3",
          }}
        />
      </Grid>
      <Grid item xs={6} md={2} textAlign='right' sx={{ paddingTop: 0.5 }}>
        <Typography
          variant='h6'
          sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
        >
          {currencyFormat(subtotal)}
        </Typography>
      </Grid>
      <Grid item xs={6} md={2} textAlign='left'>
        <Typography
          variant='h6'
          sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
        >
          GST (5%)
        </Typography>
      </Grid>
      <Grid item xs={0} md={8} sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "100%",
            borderTop: "2px solid #e3e3e3",
          }}
        />
      </Grid>
      <Grid item xs={6} md={2} textAlign='right' sx={{ paddingTop: 0.5 }}>
        <Typography
          variant='h6'
          sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
        >
          {currencyFormat(gst)}
        </Typography>
      </Grid>
      <Grid item xs={6} md={2} textAlign='left'>
        <Typography
          variant='h6'
          sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
        >
          PST (7%)
        </Typography>
      </Grid>
      <Grid item xs={0} md={8} sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "100%",
            borderTop: "2px solid #e3e3e3",
          }}
        />
      </Grid>
      <Grid item xs={6} md={2} textAlign='right' sx={{ paddingTop: 0.5 }}>
        <Typography
          variant='h6'
          sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
        >
          {currencyFormat(pst)}
        </Typography>
      </Grid>

      {gratuity! > 0 && (
        <>
          <Grid item xs={6} md={2} textAlign='left'>
            <Typography
              variant='h6'
              sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
            >
              Tip
            </Typography>
          </Grid>
          <Grid item xs={0} md={8} sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: "100%",
                borderTop: "2px solid #e3e3e3",
              }}
            />
          </Grid>
          <Grid item xs={6} md={2} textAlign='right' sx={{ paddingTop: 0.5 }}>
            <Typography
              variant='h6'
              sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
            >
              {currencyFormat(gratuity ?? 0)}
            </Typography>
          </Grid>
        </>
      )}

      {discountRate ? (
        <>
          <Grid item xs={6} md={2}>
            <Typography
              variant='h6'
              sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" }, color: "red" }}
            >
              {discountRate * 100}% off
            </Typography>
          </Grid>
          <Grid item xs={0} md={8} sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: "100%",
                borderTop: "2px solid #e3e3e3",
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={2}
            textAlign='right'
            sx={{ paddingTop: 0.5, color: "red" }}
          >
            <Typography
              variant='h6'
              sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
            >
              - {currencyFormat(discountAmount)}
            </Typography>
          </Grid>
        </>
      ) : null}

      <Grid item xs={6} md={2}>
        <Typography
          variant='h6'
          sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
        >
          Total
        </Typography>
      </Grid>
      <Grid item xs={0} md={8} sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "100%",
            borderTop: "2px solid #e3e3e3",
          }}
        />
      </Grid>
      <Grid item xs={6} md={2} textAlign='right' sx={{ paddingTop: 0.5 }}>
        <Typography
          variant='h6'
          sx={{ fontSize: { xs: "1rem", sm: "h6.fontSize" } }}
        >
          {currencyFormat(total)}
        </Typography>
      </Grid>
    </Grid>
  );
});
