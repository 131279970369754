import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import ForgotPassword from "./ForgotPassword";
import ContentContainer from "../../app/common/container/ContentContainer";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";
import { router } from "../../app/router/Routes";

interface UserFormValues {
  email: string;
  password: string;
}

export default observer(function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "onTouched",
  });
  const { theme: customTheme } = useCustomTheme();

  //bring submitForm in userStore from UserStore.ts in one line
  const { userStore, commonStore, modalStore, basketStore } = useStore();
  const { signInUser, signInUserJwt, isLoggedIn } = userStore;
  const { token, setToken } = commonStore;

  useEffect(() => {
    if (token == null || location.state?.jwt) {
      signInUserJwt();
    }
    if (isLoggedIn) {
      router.navigate(`${process.env.PUBLIC_URL}/`);
    }
  }, [signInUserJwt, location.state?.jwt, isLoggedIn]);

  async function submitForm(data: FieldValues) {
    try {
      const userData: UserFormValues = {
        email: data.email,
        password: data.password,
      };
      await signInUser(userData);
      navigate(location.state?.from || "/");
    } catch (error) {
      // toast.error("Invalid email or password");
      // console.log(error);
    }
  }

  function handleGoogleLogin() {
    window.location.href =
      process.env.REACT_APP_API_URL + "/auth/google-signin";

    // console.log(process.env.REACT_APP_API_URL + "/auth/google-signin");
  }

  const handleForgotPasswordClick = () => {
    modalStore.openModal(<ForgotPassword />);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContentContainer sx={{}}>
      <Container
        maxWidth='sm'
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component='h1' variant='h4'>
          Log In
        </Typography>
        <Box
          component='form'
          onSubmit={handleSubmit(submitForm)}
          noValidate
          sx={{ mt: 5 }}
        >
          <InputLabel>Email</InputLabel>
          <TextField
            required
            fullWidth
            autoFocus
            {...register("email", {
              required: "Email is required",
              pattern: {
                value:
                  /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                message: "Not a valid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors?.email?.message as string}
            sx={{
              mb: 2,
              "& .MuiInputBase-input": {
                borderRadius: "30px", // Set border radius to 30px
                backgroundColor: "#e3e3e3", // Set background color to #e3e3e3
                height: "1px", // Set input height to 40px
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4", // Set label color to #A0AAB4
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4", // Set label color to #A0AAB4 when focused
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px", // Set border radius to 30px
                "& fieldset": {
                  borderColor: "#e3e3e3", // Set border color to #E0E3E7
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3", // Set border color to #B2BAC2 on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3", // Set border color to #6F7E8C when focused
                },
              },
            }}
          />

          <InputLabel>Password</InputLabel>
          <TextField
            required
            fullWidth
            type='password'
            {...register("password", { required: "Password is required" })}
            error={!!errors.password}
            helperText={errors?.password?.message as string}
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "30px", // Set border radius to 30px
                backgroundColor: "#e3e3e3", // Set background color to #e3e3e3
                height: "1px", // Set input height to 40px
              },
              "& .MuiInputLabel-root": {
                color: "#A0AAB4", // Set label color to #A0AAB4
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0AAB4", // Set label color to #A0AAB4 when focused
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px", // Set border radius to 30px
                "& fieldset": {
                  borderColor: "#e3e3e3", // Set border color to #E0E3E7
                },
                "&:hover fieldset": {
                  borderColor: "#e3e3e3", // Set border color to #B2BAC2 on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e3e3e3", // Set border color to #6F7E8C when focused
                },
              },
            }}
          />

          <Typography
            variant='body2'
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
              textDecoration: "none",
              color: customTheme.primaryColor, // Set text color to orange
              cursor: "pointer",
            }}
            onClick={handleForgotPasswordClick}
          >
            Forgot password?
          </Typography>

          <LoadingButton
            disabled={!isValid}
            loading={isSubmitting}
            type='submit'
            variant='contained'
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
              margin: "auto",
              width: "50%",
              bgcolor: customTheme.primaryColor,
              "&:hover": { bgcolor: customTheme.primaryHoverColor },
              borderRadius: "30px",
              textTransform: "capitalize",
              boxShadow: "none",
            }}
          >
            Log In
          </LoadingButton>

          <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
            <Box sx={{ flexGrow: 1, borderTop: "2px solid #e3e3e3" }} />
            <Typography
              variant='body2'
              component='span'
              color='textSecondary'
              sx={{ px: 1 }}
            >
              or continue with
            </Typography>
            <Box sx={{ flexGrow: 1, borderTop: "2px solid #e3e3e3" }} />
          </Box>

          <Grid container style={{ minWidth: isMobile ? "initial" : "30rem" }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                sx={{
                  my: 1,
                  borderRadius: "30px",
                  width: "100%",
                  bgcolor: "#4285F6",
                  "&:hover": { bgcolor: "#1C6FD6" },
                }}
                onClick={handleGoogleLogin}
              >
                <Box
                  component='span'
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar
                    src={`${process.env.PUBLIC_URL}/assets/signinButtons/g-logo.png`}
                    alt='Google Icon'
                    sx={{ marginRight: 1, width: "20px", height: "20px" }}
                  />
                  Continue with Google
                </Box>
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                sx={{
                  my: 1,
                  borderRadius: "30px",
                  width: "100%",
                  bgcolor: "#3B5998",
                  "&:hover": { bgcolor: "#203D70" },
                }}
                onClick={handleFacebookLogin}
              >
                <Box
                  component='span'
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FacebookOutlined sx={{ marginRight: 1 }} />
                  Continue with Facebook
                </Box>
              </Button>
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </ContentContainer>
  );
});
