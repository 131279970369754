import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useStore } from "../../app/stores/store";
import { useNavigate } from "react-router-dom";
import UserDetail from "./UserDetail";
import { UserForAdminControls } from "../../app/models/user";

export default observer(function User() {
  const { adminStore } = useStore();
  const { users, loadUsers } = adminStore;
  const navigate = useNavigate();

  const [selectedUser, setSelectedUser] = useState<UserForAdminControls | null>(
    null
  );
  const { modalStore } = useStore();

  useEffect(() => {
    if (users.length <= 0) loadUsers();
  }, [users, loadUsers]);

  const handleOpen = (user: any) => {
    setSelectedUser(user);
    modalStore.openModal(<UserDetail selectedUser={user} />);
  };

  const handleClose = () => {
    modalStore.closeModal();
  };

  return (
    <TableContainer component={Paper} sx={{ my: 4 }}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Email</TableCell>
            {/* <TableCell>Address</TableCell> */}
            <TableCell>Phone</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow key={user.id} onClick={() => handleOpen(user)}>
              <TableCell component='th' scope='row'>
                {index + 1}
              </TableCell>
              <TableCell>
                {user.roles.map((role) => (
                  <Chip
                    key={role}
                    label={role}
                    size='small'
                    color='primary'
                    style={{ marginRight: 5 }}
                  />
                ))}
              </TableCell>

              <TableCell>{user.email}</TableCell>
              {/* <TableCell>{user.address}</TableCell> */}
              <TableCell>{user.phoneNumber}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
