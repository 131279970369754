import { makeAutoObservable, runInAction, reaction } from "mobx";
import agent from "../api/agent";
import { MetaData, PagingParams } from "../models/pagination";
import {
  MenuItem,
  MenuItemOption,
  MenuItemOptionChoice,
  MenuItemParams,
} from "../models/menuItem";
import { OptionChoiceDto } from "../models/menuItem";
import { Category } from "../models/category";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";

export default class CatalogStore {
  categories = new Map<number, Category>();
  menuItem: MenuItem | null = null;
  menuItems = new Map<number, MenuItem>();
  menuItemsLoaded = false;
  categoriesLoaded = false;
  filtersLoaded = false;
  tags: string[] = [];
  types: string[] = [];
  selectedChoices: MenuItemOptionChoice[] = [];
  pagination: MetaData | null = null;
  pagingParams = new PagingParams();
  loadingInitial = false;
  shouldResetPageNumber = false;

  menuItemParams: MenuItemParams = {
    // pageNumber: 1,
    // pageSize: 9,
    orderBy: "name",
    tags: [],
    types: [],
    searchTerm: "",
    // totalPages: 0,
  };
  metaData: MetaData | null = null;
  menuItemsUpdated = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.menuItemParams,
      () => {
        this.menuItems.clear();
        this.setLoadingInitial(true);
        this.fetchMenuItems().finally(() => {
          this.setLoadingInitial(false);
        });
      }
    );

    // this.resetMenuItemParams();
    this.fetchMenuItems();
    this.fetchCategories();
    this.fetchFilters();
  }

  get getAxiosParams() {
    const params = new URLSearchParams();
    if (this.shouldResetPageNumber) {
      params.append("resetPageNumber", "true");
    }
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("orderBy", this.menuItemParams.orderBy);
    if (this.menuItemParams.searchTerm)
      params.append("searchTerm", this.menuItemParams.searchTerm);
    if (this.menuItemParams.types.length > 0)
      params.append("types", this.menuItemParams.types.toString());
    if (this.menuItemParams.tags.length > 0)
      params.append("tags", this.menuItemParams.tags.toString());
    return params;
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetAndLoadMenuItems = async () => {
    this.pagingParams.pageNumber = 1;
    await this.fetchMenuItems();
  };

  // onFilterChange = async (params: Partial<MenuItemParams>) => {
  //   this.shouldResetPageNumber = true;
  //   this.setMenuItemParams(params);
  //   console.log(this.shouldResetPageNumber);
  //   // await this.fetchMenuItems();
  //   // this.shouldResetPageNumber = false;
  // };

  fetchMenuItems = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.Catalog.list(this.getAxiosParams);
      this.menuItems.clear();
      runInAction(() => {
        result.data.forEach((menuItem: MenuItem) => {
          if (!this.menuItems.has(menuItem.id)) {
            this.resetSelectedOptions(menuItem.options);
            this.menuItems.set(menuItem.id, menuItem);
          }
        });

        this.setPagination(result.pagination);
        this.setLoadingInitial(false);
        this.shouldResetPageNumber = false;
        this.menuItemsLoaded = true;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  setPagination = (pagination: MetaData) => {
    this.pagination = pagination;
  };

  fetchCategories = async () => {
    try {
      const result = await agent.Catalog.categories();
      runInAction(() => {
        result.forEach((category: Category) => {
          this.categories.set(category.id, category);
          this.categoriesLoaded = true;
        });
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  fetchMenuItem = async (id: number) => {
    this.setLoadingInitial(true);

    try {
      let menuItem: MenuItem | null = null;
      const response = await agent.Catalog.details(id);
      if (response) {
        menuItem = response as MenuItem;

        // Set initial option values
        menuItem.options.forEach(
          (option: { required: any; choices: any[]; id: number }) => {
            if (option.required) {
              const firstChoice = option.choices[0];
              if (firstChoice) {
                this.updateMenuItemOption(option.id, firstChoice.id);
              }
            } else {
              option.choices.forEach((choice: MenuItemOptionChoice) => {
                this.setOptionChoiceSelected(option.id, choice.id, false);
              });
            }
          }
        );
        this.setMenuItem(menuItem);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setOptionChoiceSelected(
    optionId: number,
    choiceId: number,
    selected: boolean
  ) {
    const targetMenuItem = Array.from(this.menuItems.values()).find(
      (menuItem: MenuItem) =>
        menuItem.options &&
        menuItem.options.some(
          (option: MenuItemOption) => option.id === optionId
        )
    );

    if (targetMenuItem) {
      const targetOption = targetMenuItem.options.find(
        (option: MenuItemOption) => option.id === optionId
      );

      if (targetOption) {
        const targetChoice = targetOption.choices.find(
          (choice: MenuItemOptionChoice) => choice.id === choiceId
        );

        if (targetChoice) {
          targetChoice.selected = selected;
        }
      }
    } else {
      console.error("No menuItem found with the given optionId:", optionId);
    }
  }

  updateMenuItemOption(optionId: number, choiceId: number) {
    const targetMenuItem = Array.from(this.menuItems.values()).find(
      (menuItem: MenuItem) =>
        menuItem.options &&
        menuItem.options.some(
          (option: MenuItemOption) => option.id === optionId
        )
    );

    if (targetMenuItem) {
      const targetOption = targetMenuItem.options.find(
        (option: MenuItemOption) => option.id === optionId
      );

      if (targetOption) {
        if (targetOption.required) {
          // Radio button behavior
          targetOption.choices.forEach((choice: MenuItemOptionChoice) => {
            this.setOptionChoiceSelected(
              optionId,
              choice.id,
              choice.id === choiceId
            );
          });
        } else {
          // Checkbox behavior
          const selectedChoices = targetOption.choices.filter(
            (choice: MenuItemOptionChoice) => choice.selected
          );
          const totalPrice = selectedChoices.reduce(
            (acc: number, choice: MenuItemOptionChoice) => acc + choice.price,
            0
          );
          targetOption.optionTotalprice = totalPrice;

          targetOption.choices.forEach((choice: MenuItemOptionChoice) => {
            this.setOptionChoiceSelected(
              optionId,
              choice.id,
              choice.id === choiceId ? !choice.selected : choice.selected
            );
          });
        }
      }
    } else {
      console.error("No menuItem found with the given optionId:", optionId);
    }
  }

  fetchFilters = async () => {
    try {
      const response = await agent.Catalog.fetchFilters();
      const menuItemResponse: MenuItemParams = response as MenuItemParams;
      runInAction(() => {
        this.tags = menuItemResponse.tags;
        this.types = menuItemResponse.types;
        this.filtersLoaded = true;
      });
    } catch (error) {
      console.log(error);
    }
  };

  setMenuItemParams = (params: Partial<MenuItemParams>) => {
    this.menuItemParams = { ...this.menuItemParams, ...params };
    this.shouldResetPageNumber = true;
  };

  setPageNumber = (pageNumber: number) => {
    this.menuItemParams = { ...this.menuItemParams };
    this.metaData = { ...this.metaData!, currentPage: pageNumber };
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setMenuItem = (menuItem: MenuItem | null) => {
    this.menuItem = menuItem;
  };

  removeMenuItem = (id: number) => {
    this.menuItems.delete(id);
  };

  getSelectedOptions(options: MenuItemOption[]) {
    const selectedOptions: OptionChoiceDto[] = [];

    options.forEach((option) => {
      option.choices.forEach((choice) => {
        if (choice.selected) {
          selectedOptions.push({
            optionId: option.id,
            choiceId: choice.id,
          });
        }
      });
    });

    return selectedOptions;
  }

  resetSelectedOptions = (options: MenuItemOption[]) => {
    options.forEach((option) => {
      option.choices.forEach((choice) => {
        choice.selected = option.required
          ? option.choices[0].id === choice.id
          : false;
      });
    });
  };

  setSelectedOptionsFromBasket(optionChoices: OptionChoiceDto[] | undefined) {
    if (!optionChoices) return;

    this.menuItems.forEach((menuItem) => {
      menuItem.options.forEach((option) => {
        option.choices.forEach((choice) => {
          const isSelected = optionChoices.some(
            (selectedOption) =>
              selectedOption.optionId === option.id &&
              selectedOption.choiceId === choice.choiceId
          );
          choice.selected = isSelected;
        });
      });
    });
  }

  loadMoreMenuItems = async () => {
    if (
      this.pagination &&
      this.pagination.currentPage < this.pagination.totalPages
    ) {
      const newPagingParams = new PagingParams(
        this.pagingParams.pageNumber + 1,
        this.pagingParams.pageSize
      );
      this.setPagingParams(newPagingParams);
      await this.fetchMenuItems();
    }
  };

  addMenuItem = async (menuItemData: FieldValues) => {
    try {
      const newMenuItem = await agent.Admin.createMenuItem(menuItemData);
      this.fetchMenuItems();
      this.setMenuItem(newMenuItem);
      toast.success("MenuItem added successfully");
    } catch (error) {
      console.log(error);
      toast.error("Problem adding menuItem");
    }
  };

  updateMenuItem = async (menuItemData: FieldValues) => {
    try {
      const updatedMenuItem = await agent.Admin.updateMenuItem(menuItemData);
      this.fetchMenuItems();
      this.setMenuItem(updatedMenuItem);
      this.setMenuItemsUpdated(true);
      toast.success("MenuItem updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Problem updating menuItem");
    }
  };

  updateMenuItemDisplayOrder = async (
    menuItemId: number,
    newDisplayOrder: number
  ) => {
    const menuItem = this.menuItems.get(menuItemId);
    if (!menuItem) return;

    try {
      const updatedMenuItemData = {
        ...menuItem,
        displayOrder: newDisplayOrder,
      };
      const response = await agent.Admin.updateMenuItem(updatedMenuItemData);

      runInAction(() => {
        if (response && this.menuItems.has(menuItemId)) {
          const existingItem = this.menuItems.get(menuItemId);
          if (existingItem) {
            existingItem.displayOrder = response.displayOrder;
            this.menuItems.set(menuItemId, existingItem);
            toast.success("Display order updated successfully");
          }
        } else {
          console.error(
            "Failed to update menu item: Item not found or invalid response"
          );
          toast.error("Failed to update display order");
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Problem updating display order");
    }
  };

  setMenuItemsUpdated = async (value: boolean) => {
    this.menuItemsUpdated = value;
  };
}
