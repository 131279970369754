import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../app/api/agent";
import {
  DeleteAccountFormValues,
  User,
  UserFormPasswordUpdateValues,
  UserFormUpdateValues,
  UserFormValues,
} from "../../app/models/user";
import { router } from "../../app/router/Routes";
import { toast } from "react-toastify";
import { store } from "./store";

export default class UserStore {
  user: User | null = null;
  static user: any;
  isUpdated = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  signInUser = async (data: any) => {
    try {
      const userDto = await agent.Account.login(data);
      const { basket, ...user } = userDto;
      if (basket) {
        store.basketStore.setBasket(basket);
      }

      const claims = JSON.parse(atob(user.token.split(".")[1]));
      const roles = claims["role"];
      user.roles = typeof roles === "string" ? [roles] : roles;

      this.setUser(user);
      localStorage.setItem("user", JSON.stringify(user));

      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));

      if (user.roles.includes("Admin")) {
        router.navigate(`${process.env.PUBLIC_URL}/admin`);
      } else {
        router.navigate(`${process.env.PUBLIC_URL}`);
      }

      store.modalStore.closeModal();
    } catch (error: any) {
      console.log(error);
      toast.error(error!.data.message || "Login failed");
      throw error;
    }
  };

  signInUserJwt = async () => {
    try {
      const userDto = (await agent.Account.loginJwt()) || {};
      const { basket, ...user } = userDto;

      if (!user.token || user.token.split(".").length < 2) {
        console.error("Invalid or missing token");
        return;
      }

      const base64Url = user.token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const claims = JSON.parse(atob(base64));
      const roles = claims["role"] || []; // Default to an empty array if no roles are found
      user.roles = Array.isArray(roles) ? roles : [roles];

      if (user) {
        this.setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        store.commonStore.setToken(user.token);
        localStorage.setItem("jwt", user.token);
        runInAction(() => (this.user = user));
        router.navigate(`${process.env.PUBLIC_URL}/`);
      }

      if (basket) {
        store.basketStore.setBasket(basket);
      }
      // store.modalStore.closeModal();
    } catch (error) {
      // toast.error("Login failed");
      throw error;
    }
  };

  register = async (creds: UserFormValues) => {
    try {
      const user = await agent.Account.register(creds);
      console.log(user);
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  async sendResetPasswordEmail(formData: { email: string }) {
    try {
      const response = await agent.Account.forgotPassword(formData.email);
      if (response.status === 200) {
        return true;
      } else {
        toast.error(response.data || "Failed to send reset password");
        return false;
      }
    } catch (error: any) {
      console.error("Error sending reset password email:", error);
      toast.error(error!.data || "Failed to send reset password");

      return false; // Fail
    }
  }

  fetchCurrentUser = async () => {
    const storedUser = JSON.parse(localStorage.getItem("user")!);
    if (storedUser) {
      this.setUser(storedUser);
      return;
    }
    try {
      const userDto = await agent.Account.current();
      const { basket, ...user } = userDto;
      if (basket) {
        store.basketStore.setBasket(basket);
      }

      const claims = JSON.parse(atob(user.token.split(".")[1]));
      const roles = claims["role"];

      user.roles = typeof roles === "string" ? [roles] : roles;

      localStorage.setItem("user", JSON.stringify(user));
      this.setUser(user);
    } catch (error) {
      // console.log(error);
      // this.signOut();
      // router.navigate("/login");
    }
  };

  signOut = async () => {
    // const userDto = await agent.Account.current();
    // if (userDto.kakaoId) {
    //   agent.Account.kakaoLogout().then((res) => {
    //     window.location.href = res;
    //   });
    // }
    store.modalStore.closeModal();
    store.basketStore.clearBasket();
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    router.navigate(process.env.PUBLIC_URL);
    this.user = null;
    await agent.Account.logout();
  };

  setUser = (user: User) => {
    this.user = user;
  };

  setImage = (image: string) => {
    if (this.user) this.user.image = image;
  };

  setUserPhoto = (url: string) => {
    if (this.user) this.user.image = url;
  };

  setDisplayName = (name: string) => {
    if (this.user) this.user.displayName = name;
  };

  updateProfile = async (profile: UserFormUpdateValues) => {
    try {
      console.log(profile);
      await agent.Account.updateProfile(profile).then((response) => {
        this.setUser(response);
        toast.success("User information updated successfully");
      });
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  updatePassword = async (password: UserFormPasswordUpdateValues) => {
    try {
      await agent.Account.updatePassword(password).then((response) => {
        console.log(response);
        this.setUser(response);
        toast.success("User password updated successfully");
        this.isUpdated = true;
      });
    } catch (error: any) {
      console.error("Error updating profile:", error);
      toast.error(error.data[0].description || error.data);
    }
  };

  deleteUserAccount = async (values: DeleteAccountFormValues) => {
    try {
      await agent.Account.deleteUserAccount(values).then((response) => {
        if (response.status === 200) {
          this.signOut();
          toast.success(response.message);
        }
      });
    } catch (error: any) {
      console.log(error);
    }
  };
}
