import { Remove, Add } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { BasketItem } from "../../app/models/basket";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { currencyFormat } from "../../app/util/util";
import UpdateBasketItem from "./UpdateBasketItem";
import { useState } from "react";
import BasketSummary from "./BasketSummary";

interface Props {
  isBasket?: boolean;
}

export default observer(function BasketTable({ isBasket = true }: Props) {
  const { basketStore, modalStore } = useStore();
  const { removeItem, status, subtotal, updateItem } = basketStore;

  const items = basketStore.basket ? basketStore.basket.items : [];

  const [localItemsCount, setLocalItemsCount] = useState<Map<string, number>>(
    () => {
      const initialCounts = new Map<string, number>();
      items.forEach((item) => {
        initialCounts.set(item.id + "-" + item.menuItemId, item.quantity);
      });
      return initialCounts;
    }
  );

  const handleItemQuantityChange = (itemId: string, newQuantity: number) => {
    setLocalItemsCount((prevState) => {
      const newState = new Map(prevState);
      newState.set(itemId, newQuantity);
      return newState;
    });
  };

  const handleRemoveItem = async (
    basketItemId: number,
    menuItemId: number,
    quantity: number,
    type: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    await removeItem(basketItemId, menuItemId, quantity, type);

    setLocalItemsCount((prevState) => {
      const newState = new Map(prevState);
      const currentCount = newState.get(basketItemId + "-" + menuItemId) || 0;
      const newCount =
        type === "del" ? 0 : Math.max(currentCount - quantity, 0);
      newState.set(basketItemId + "-" + menuItemId, newCount);
      return newState;
    });
  };

  const handleAddItem = (
    basketItem: BasketItem,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    const optionChoiceDto = basketStore.mapOptionChoicesToDto(
      basketItem.optionChoices
    );

    const currentCount =
      localItemsCount.get(basketItem.id + "-" + basketItem.menuItemId) || 0;

    updateItem(
      basketItem.id,
      basketItem.menuItemId,
      currentCount + 1,
      optionChoiceDto
    );

    setLocalItemsCount(
      localItemsCount.set(
        basketItem.id + "-" + basketItem.menuItemId,
        currentCount + 1
      )
    );
  };

  const handleClickItem = (item: BasketItem) => {
    modalStore.openModal(
      <UpdateBasketItem
        basketItem={item}
        onQuantityChange={handleItemQuantityChange}
      />
    );
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
        my: 3,
        mx: 1,
      }}
    >
      <CardContent>
        <Grid container spacing={2} sx={{ marginBottom: "2rem" }}>
          {items.map((item, index) => (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: index > 0 ? "1rem" : 0,
                }}
                direction={{ xs: "column", sm: "row", md: "row" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  onClick={() => handleClickItem(item)}
                  sx={{ cursor: "pointer" }}
                >
                  <CardHeader title={item.name} />
                  <CardContent sx={{ marginTop: -2 }}>
                    <Typography
                      variant='body2'
                      gutterBottom
                      sx={{ color: "gray" }}
                    >
                      {item.description}
                    </Typography>
                    {item.optionChoices &&
                      item.optionChoices.map((option) => {
                        return (
                          <Typography
                            key={option.id}
                            variant='body2'
                            gutterBottom
                            sx={{
                              fontSize: { xs: "1rem", sm: "h6.fontSize" },
                            }}
                          >
                            {option.name}: {currencyFormat(option.price)}
                          </Typography>
                        );
                      })}
                    <Typography
                      variant={"h6"}
                      sx={{
                        fontSize: { xs: "1rem", sm: "h6.fontSize" },
                      }}
                    >
                      {currencyFormat(subtotal(item))}
                    </Typography>
                  </CardContent>
                </Grid>
                {isBasket && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      disabled={
                        status ===
                        "pendingRemoveItem" +
                          item.id +
                          "-" +
                          item.menuItemId +
                          "rem"
                      }
                      onClick={(event) =>
                        handleRemoveItem(
                          item.id,
                          item.menuItemId,
                          1,
                          "rem",
                          event
                        )
                      }
                      sx={{
                        color: "#000",
                        borderRadius: "100%",
                        width: "20px !important",
                        height: "20px !important",
                        backgroundColor: "#dedede",
                        marginInline: "1rem",
                      }}
                    >
                      <Remove sx={{ color: "#000", fontSize: "1rem" }} />
                    </IconButton>
                    <Typography
                      variant={"h6"}
                      gutterBottom
                      sx={{ mx: 2, display: "inline-block" }}
                    >
                      {localItemsCount.get(item.id + "-" + item.menuItemId) ||
                        0}
                    </Typography>
                    <IconButton
                      disabled={
                        status ===
                        "pendingAddItem" + item.id + "-" + item.menuItemId
                      }
                      onClick={(event) => handleAddItem(item, event)}
                      sx={{
                        color: "#000",
                        borderRadius: "100%",
                        width: "20px !important",
                        height: "20px !important",
                        backgroundColor: "#dedede",
                        marginInline: "1rem",
                      }}
                    >
                      <Add sx={{ color: "#000", fontSize: "1rem" }} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              {index >= 0 && (
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      marginBottom: "1rem",
                      bgcolor: "e3e3e3",
                      height: "2px",
                    }}
                  />
                </Grid>
              )}
            </>
          ))}
        </Grid>
        <BasketSummary />
      </CardContent>
    </Card>
  );
});
