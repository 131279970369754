import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { MenuItem } from "../../app/models/menuItem";
import {
  Typography,
  TableContainer,
  TextField,
  FormControlLabel,
  Radio,
  Checkbox,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardHeader,
  tableCellClasses,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import { currencyFormat } from "../../app/util/util";
import ContentContainer from "../../app/common/container/ContentContainer";

export default observer(function MenuItemDetails() {
  const { catalogStore, basketStore } = useStore();
  const { getSelectedOptions, menuItem, fetchMenuItem } = catalogStore;
  const { basket, addItem } = basketStore;

  const { id } = useParams<{ id: string }>();

  if (menuItem === null) fetchMenuItem(parseInt(id!));

  const options = menuItem?.options || [];
  const [quantity, setQuantity] = useState(1);
  const item =
    basket && basket.items
      ? basket.items.find((i) => i.menuItemId === menuItem?.id)
      : undefined;

  const selectedOptionPrice = options.reduce((total, option) => {
    const selectedChoices = option.choices.filter((choice) => choice.selected);
    const choicePrices = selectedChoices.map((choice) => choice.price);
    const optionPrice = choicePrices.reduce((a, b) => a + b, 0);
    return total + optionPrice;
  }, 0);

  function handleInputChange(e: any) {
    if (e.target.value >= 0) {
      let newQuantity = parseInt(e.target.value);
      if (newQuantity <= 0) {
        newQuantity = 1;
      }

      setQuantity(newQuantity);
    }
  }

  function handleUpdateCart() {
    if (quantity > 0) {
      const updatedQuantity = item ? quantity - item.quantity : quantity;
      const selectedOptions = getSelectedOptions(menuItem!.options);
      addItem(item?.id!, menuItem?.id!, quantity, selectedOptions);

      catalogStore.resetSelectedOptions(menuItem!.options);
    }
  }

  function setQuantityHandler(value: number) {
    if (value >= 0) {
      let newQuantity = value;
      if (newQuantity < 0) {
        newQuantity = 0;
      }
      setQuantity(newQuantity);
    }
  }

  function handleChoiceChange(optionId: number, choiceId: number) {
    catalogStore.updateMenuItemOption(optionId, choiceId);
  }

  return (
    <ContentContainer>
      {menuItem && (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardMedia
                component='img'
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "20rem",
                  bgcolor: "primary.light",
                }}
                image={
                  menuItem.pictureUrl
                    ? menuItem.pictureUrl
                    : `${process.env.PUBLIC_URL}/assets/placeholder.png`
                }
                alt={menuItem.name}
              />

              <CardContent>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableBody sx={{ fontSize: "1.1em" }}>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{menuItem.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>{menuItem.description}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Price</TableCell>
                        <TableCell>{currencyFormat(menuItem.price)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>{menuItem.category}</TableCell>
                      </TableRow>
                      {menuItem.subCategory && (
                        <TableRow>
                          <TableCell>Subcategory</TableCell>
                          <TableCell>{menuItem.subCategory}</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>Quantity in stock</TableCell>
                        <TableCell>{menuItem.quantityInStock}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ my: 2 }}>
            <Grid container spacing={2}>
              {options.map((option) => (
                <Grid item xs={12} md={12} lg={12} key={option.id}>
                  <Card>
                    <CardHeader title={option.name} />
                    <CardContent>
                      <TableContainer>
                        <Table
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none",
                            },
                          }}
                        >
                          <TableBody>
                            {option.choices.map((choice) => (
                              <TableRow key={choice.id}>
                                <TableCell>
                                  {option.required ? (
                                    <FormControlLabel
                                      value={choice.id}
                                      label={`${choice.name} +${currencyFormat(
                                        choice.price
                                      )}`}
                                      control={
                                        <Radio sx={{ color: "primary.main" }} />
                                      }
                                      checked={choice.selected}
                                      onChange={() =>
                                        handleChoiceChange(option.id, choice.id)
                                      }
                                    />
                                  ) : (
                                    <FormControlLabel
                                      value={choice.id}
                                      label={`${choice.name} +${currencyFormat(
                                        choice.price
                                      )}`}
                                      control={
                                        <Checkbox
                                          sx={{ color: "primary.main" }}
                                        />
                                      }
                                      checked={choice.selected}
                                      onChange={() => {
                                        handleChoiceChange(
                                          option.id,
                                          choice.id
                                        );
                                      }}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <Grid
                  container
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid item>
                    <Typography variant='h5'>Quantity</Typography>
                  </Grid>
                  <CardContent>
                    <Grid
                      container
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Grid item container alignItems='center'>
                        <Grid item>
                          <RemoveIcon
                            onClick={() => setQuantityHandler(quantity - 1)}
                            sx={{ cursor: "pointer" }}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            sx={{ width: "5rem", textAlign: "center" }}
                            value={quantity}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </Grid>
                        <Grid item>
                          <AddIcon
                            onClick={() => setQuantityHandler(quantity + 1)}
                            sx={{ cursor: "pointer" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <LoadingButton
                    loading={basketStore.status !== "idle"}
                    onClick={() => handleUpdateCart()}
                    sx={{ width: "100%" }}
                    variant='contained'
                    size='large'
                  >
                    {quantity > 0
                      ? `${currencyFormat(
                          menuItem.price * quantity +
                            selectedOptionPrice * quantity
                        )} Add To Cart`
                      : "0 item quantity"}
                  </LoadingButton>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </ContentContainer>
  );
});
