import { useState } from "react";
import { Box, Tab, Tabs, Paper, Container } from "@mui/material";

import InventoryPanel from "./Inventory";
import CategoryPanel from "./Category";
import UserPanel from "./User";
import OrderPanel from "./Order";

import { observer } from "mobx-react-lite";

export default observer(function AdminPage() {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Container sx={{ flexGrow: 1 }}>
      <Paper>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          {/* <Tab label='Order List' /> */}
          <Tab label='Inventory' />
          <Tab label='Category' />
          <Tab label='User' />
        </Tabs>
      </Paper>
      {/* {activeTab === 0 && <OrderPanel />} */}
      {activeTab === 0 && <InventoryPanel />}
      {activeTab === 1 && <CategoryPanel />}
      {activeTab === 2 && <UserPanel />}
    </Container>
  );
});
