import { observer } from "mobx-react-lite";
import { Modal, Box, IconButton, Typography } from "@mui/material";
import { useStore } from "../../stores/store";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default observer(function ModalContainer() {
  const { modalStore } = useStore();

  useEffect(() => {
    const handlePopState = (event: any) => {
      if (modalStore.modal.open) {
        event.preventDefault();
        modalStore.closeModal();
      }
    };

    window.history.pushState(null, document.title, window.location.href);

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [modalStore]);

  return (
    <Modal
      open={modalStore.modal.open}
      onClose={modalStore.closeModal}
      disablePortal
      closeAfterTransition
    >
      <Box
        sx={{
          position: "absolute",
          height: "auto",
          maxHeight: "100%",
          width: { xs: "90vw", sm: "30rem", md: "40rem" },
          bgcolor: "background.paper",
          p: { xs: 2, sm: 5, md: 5 },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflowY: "auto",
          borderRadius: "20px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={modalStore.closeModal}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography>{modalStore.modal.body}</Typography>
      </Box>
    </Modal>
  );
});
