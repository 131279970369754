import { Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useTheme as useCustomTheme } from "../../app/contexts/ThemeContext";

export default observer(function PlaceAnOrderBtnSection() {
  const { basketStore } = useStore();
  const { theme: customTheme } = useCustomTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 300,
        background:
          "radial-gradient(circle 55rem at bottom, rgba(255, 115, 0, 0.2) 10%, rgba(255, 115, 0, 0) 40%)",
      }}
    >
      {basketStore.ItemCountTotal > 0 && (
        <Button
          variant='contained'
          sx={{
            bgcolor: customTheme.primaryColor,
            "&:hover": { bgcolor: customTheme.primaryHoverColor },
            borderRadius: "20px",
            textTransform: "capitalize",
            boxShadow: "none",
            color: "#fff !important",
          }}
          size='large'
          component={RouterLink}
          to='/checkout'
        >
          Place an Order ({basketStore.ItemCountTotal})
        </Button>
      )}
    </Box>
  );
});
