import { Elements } from "@stripe/react-stripe-js";
import CheckoutPage from "./CheckoutPage";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import CheckoutDetails from "./CheckoutDetails";

const StripeApiKey = `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`;
const stripePromise = loadStripe(StripeApiKey);

export default function CheckoutWrapper() {
  const [loading, setLoading] = useState(true);
  const { basketStore } = useStore();
  const { setBasket } = basketStore;

  useEffect(() => {
    agent.Payments.createPaymentIntent()
      .then((response) => setBasket(response))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [setBasket]);

  if (loading) return <LoadingComponent />;

  return (
    <Elements stripe={stripePromise}>
      {/* <CheckoutPage /> */}
      <CheckoutDetails />
    </Elements>
  );
}
