// src/stores/BasketStore.ts
import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { Basket, BasketItem } from "../models/basket";
import { OptionChoiceDto, MenuItemOptionChoice } from "../models/menuItem";
import { MenuItemOption } from "../models/menuItem";
import { currencyFormat } from "../util/util";
import { Order } from "../models/order";
import { toast } from "react-toastify";
import { store } from "./store";

class BasketStore {
  basket: Basket | null = null;
  status: string = "idle";
  selectedOrder: Order | null = null;
  gratuity: number = 0;

  constructor() {
    makeObservable(this, {
      basket: observable,
      status: observable,
      fetchBasket: action,
      addItem: action,
      removeItem: action,
      setBasket: action,
      clearBasket: action,
      setGratuity: action,
      gratuity: observable,
    });
  }

  setGratuity = (amount: number) => {
    this.gratuity = amount;
  };

  fetchBasket = async () => {
    this.status = "loading";
    try {
      const basket = await agent.Basket.get();
      runInAction(() => {
        this.setBasket(basket);
        this.status = "idle";
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.status = "idle";
      });
    }
  };

  addItem = async (
    basketItemId: number,
    menuItemId: number,
    quantity: number = 1,
    optionChoices?: OptionChoiceDto[]
  ) => {
    this.status = "pendingAddItem" + basketItemId + "-" + menuItemId;
    try {
      const basket = await agent.Basket.addItem(
        menuItemId,
        quantity,
        optionChoices
      );
      runInAction(() => {
        this.setBasket(basket);
      });
      toast.success("Basket item added");
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.status = "idle";
      });
    }
  };

  updateItem = async (
    basketItemId: number,
    menuItemId: number,
    quantity: number = 1,
    optionChoices?: OptionChoiceDto[]
  ) => {
    this.status = "pendingUpdateItem" + menuItemId;
    try {
      if (quantity === 0)
        return await this.removeItem(basketItemId, menuItemId, 1, "del");

      const basket = await agent.Basket.updateItem(
        basketItemId,
        menuItemId,
        quantity,
        optionChoices
      );
      runInAction(() => {
        this.setBasket(basket);
      });
      toast.success("Basket item updated");
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.status = "idle";
      });
    }
  };

  removeItem = async (
    basketItemId: number,
    menuItemId: number,
    quantity: number,
    type?: string
  ) => {
    this.status = "pendingRemoveItem" + basketItemId + "-" + menuItemId + type;
    try {
      await agent.Basket.removeItem(basketItemId, menuItemId, quantity);
      runInAction(() => {
        const itemIndex = this.basket?.items.findIndex(
          (i: BasketItem) =>
            i.menuItemId === menuItemId && i.id === basketItemId
        );
        if (itemIndex === -1 || itemIndex === undefined) return;

        if (type === "del") {
          this.basket?.items.splice(itemIndex, 1);
        } else {
          this.basket!.items[itemIndex].quantity -= quantity;
          if (this.basket!.items[itemIndex].quantity <= 0)
            this.basket?.items.splice(itemIndex, 1);
        }
        this.status = "idle";
      });
      toast.info("Basket item deleted");
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.status = "idle";
      });
    }
  };

  setBasket = (basket: Basket) => {
    this.basket = basket;
  };

  clearBasket = () => {
    this.basket = null;
  };

  get getTotal() {
    let total = 0;

    // Check if basket is undefined or null
    if (!this.basket) return 0;

    // Ensure that basket.items is defined and is an array
    if (!Array.isArray(this.basket.items)) return 0;

    this.basket.items.forEach((item) => {
      const itemSubTotal = this.subtotal(item);
      const gst = itemSubTotal * 0.05;
      let taxTotal = gst;

      const category = store.categoryStore.categoryRegistry.get(
        item.categoryId
      );
      if (category?.name === "Beverages") {
        const pst = itemSubTotal * 0.07;
        taxTotal += pst;
      }
      total += itemSubTotal + taxTotal;
    });

    return total;
  }

  getTotalDetails() {
    let subtotal = 0;
    let gst = 0;
    let pst = 0;

    if (!this.basket)
      return { subtotal, gst, pst, total: 0, gratuity: this.gratuity };

    this.basket.items.forEach((item) => {
      const itemSubTotal = this.subtotal(item);
      subtotal += itemSubTotal;

      gst += itemSubTotal * 0.05; // Always apply GST

      const category = store.categoryStore.categoryRegistry.get(
        item.categoryId
      );

      if (category?.name === "Beverages") {
        pst += itemSubTotal * 0.07; // Apply PST only to "Drink"
      }
    });

    const discountRate = parseFloat(process.env.REACT_APP_DISCOUNT_RATE ?? "0");
    const discountAmount = subtotal * discountRate;
    const discountedSubtotal = subtotal - discountAmount;

    const totalBeforeGratuity = discountedSubtotal + gst + pst;

    const total = totalBeforeGratuity + this.gratuity;

    return {
      subtotal,
      gst,
      pst,
      total,
      gratuity: this.gratuity,
      discountAmount,
    };
  }

  itemCount = (menuItemId: number) => {
    const item = this.basket?.items.find(
      (item: { menuItemId: number }) => item.menuItemId === menuItemId
    );
    return item ? item.quantity : 0;
  };

  subtotal = (item: BasketItem) => {
    const basePrice = item.price * item.quantity;
    let optionsPrice = 0;
    if (item.optionChoices && Array.isArray(item.optionChoices)) {
      item.optionChoices.forEach((option) => {
        optionsPrice += option.price;
      });
    }

    const totalPrice = basePrice + optionsPrice * item.quantity;

    return totalPrice;
  };

  get ItemCountTotal() {
    return (
      this.basket?.items?.reduce((sum, item) => sum + item.quantity, 0) || 0
    );
  }

  isInBasket = (menuItemId: number) => {
    return this.basket?.items.some(
      (item: { menuItemId: number }) => item.menuItemId === menuItemId
    );
  };

  loadOrder = async (id: number, order?: Order) => {
    this.selectedOrder = order ?? null;
    if (!this.selectedOrder) {
      try {
        const resultOrder = await agent.Orders.fetch(id);
        this.selectedOrder = resultOrder;
      } catch (error) {
        console.log(error);
      }
    }
  };

  clearSelectedOrder = () => {
    this.selectedOrder = null;
  };

  mapOptionChoicesToDto = (options: MenuItemOption[]): OptionChoiceDto[] => {
    const optionChoiceDtos: OptionChoiceDto[] = [];

    options.forEach((option) => {
      optionChoiceDtos.push({
        optionId: -1,
        choiceId: option.id,
      });
    });

    return optionChoiceDtos;
  };

  updateQuantity(menuItemId: number, newQuantity: number) {
    const itemToUpdate = this.basket?.items.find(
      (item) => item.menuItemId === menuItemId
    );

    if (!itemToUpdate) return;

    runInAction(() => {
      itemToUpdate.quantity = newQuantity;
    });
  }
}

export default BasketStore;
